import React from "react";
import Button from "../components/button";

const Index = () => {
  return (
    <div className="container">
      <div className="row">
        <Button color="primary" title="Primary" />
        <Button color="secondary" title="Secondary" />
        <Button color="success" title="Success" />
        <Button color="warning" title="Warning" />
        <Button color="danger" title="Danger" />
        <Button color="light" title="Light" />
        <Button color="dark" title="Dark" />
      </div>
      <div className="row">
        <Button variant="outline" color="primary" title="Primary" />
        <Button variant="outline" color="secondary" title="Secondary" />
        <Button variant="outline" color="success" title="Success" />
        <Button variant="outline" color="warning" title="Warning" />
        <Button variant="outline" color="danger" title="Danger" />
        <Button variant="outline" color="light" title="Light" />
        <Button variant="outline" color="dark" title="Dark" />
      </div>
    </div>
  );
};

export default Index;
