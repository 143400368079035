import React, { useRef, useState, } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import UploadIcon from "../../../assets/img/gallery/image.png"


const InputPromoItems = ({ action, data, register, toggle }) => {

    const formikRef = useRef();
    const formData = new FormData();
    const fileRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""});    

    const initial = data ? {
        id: data?.id,
        radius: data?.radius,
        max_participant_per_radius: data?.max_participant_per_radius,
        twibbon : `${process.env.REACT_APP_API_URL}${data?.twibbon_url}`
    } : {
        radius: "",
        max_participant_per_radius: "",
        twibbon: ""
    }

    const validation = Yup.object().shape({
        radius: Yup.string().required("Required").nullable(),
        max_participant_per_radius: Yup.string().required("Required").nullable(),
        twibbon : Yup.mixed()
        .test('fileFormat', 'Empty File', (value) => {
            console.log(value)
            if (!value) {
                setFile({error:"Foto tidak boleh kosong"})
                return false
            } else if((value.size/1024/1024) > 2) {
                setFile({error:"Ukuran foto tidak boleh lebih dari 2MB"})
                setImage({
                    preview: "",
                    raw: "",
                    filename: ""
                });
                return false
            }else {
                setFile({error:""})
                return true
            }

        }),
    })

    const initialTouched = {
        radius: true,
        max_participant_per_radius: true,
    }

    const handleRegister = () => {
        const value = formikRef.current.values
        formikRef.current.validateForm().then(
            () => {
                if (formikRef.current.isValid) {
                    register(value)
                }
            }
        );

    }

    const handleChangeImage = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('twibbon',e.target.files[0])

            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    if (values.id) {
                        formData.append('id',values.id)
                    }
                    formData.append('radius',values.radius)
                    formData.append('max_participant_per_radius',values.max_participant_per_radius)
                    formData.append('twibbon_image',values.twibbon)

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <Input
                                name="radius"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("radius")}
                                title="Radius Lokasi Promo"
                                placeholder="Tulis Jarak Radius Lokasi Promo"
                                type="text"
                            />
                            <Input
                                name="max_participant_per_radius"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("max_participant_per_radius")}
                                title="Limit Peserta per Radius"
                                placeholder="Tulis Limit Peserta per Radius"
                                type="text"
                            />
                            <div className="form-group">
                                <label className="form-label">Twibbon</label>
                                <div className="box-upload" >
                                <label htmlFor="upload-button">
                                    {image.preview ? (
                                    <img className="img-preview" src={image.preview} alt="dummy"  />
                                    ) : (
                                    <>
                                        <img className="img-preview" src={initial.twibbon ? initial.twibbon : UploadIcon} />
                                    </>
                                    )}
                                    <div className="filename">
                                        <p><b>Format Twibbon</b></p>
                                        <p>{image.filename ? image.filename : "jpg, png, Max 2MB"}</p>
                                    </div>
                                </label>
                                </div>
                                {fileImage.error ? (
                                    <p class="warn-text">{fileImage.error}</p>
                                ) : (
                                    <p></p>
                                )}
                                <input
                                    type="file"
                                    ref={fileRef}
                                    id="upload-button"
                                    style={{ display: "none" }}
                                    onChange={(e)=> handleChangeImage(e, props)}
                                    accept="image/png, image/jpeg"
                                    errors={props?.errors}
                                />
                            </div>
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={8}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Simpan Data
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default InputPromoItems;
