import { useRef, useState } from "react";
import DropdownMenu from "../../dropdowns/DropdownMenu";
import account from "../../../assets/img/account.png";
import { getUser } from "../../../utils/User";
import { history } from "../../../utils/History";
import { useClickaway } from "../../hooks/ClickawayHooks";
import './sidebar.scss'


const Index = ({toggle}) => {
  const currentUser = getUser();
  const name = currentUser?.username;
  const position = currentUser?.role?.name;
  const [show, setShow] = useState(false);

  const dropdownRef = useRef()

  const logout = () => {
    localStorage.removeItem('user_promo')
    localStorage.removeItem("persist:root");
    history.push(`/`);
}
  useClickaway(dropdownRef, () => {
      setShow(false)
  })

  return (
    <ul className="profile-menu" onClick={() => setShow(!show)}>
      <li className="profile-items">
        <a>
          <img src={account} className="profile-image" alt="account" />
          <div className="profile-info hover-show">
                <p className="profile-name text-bold">{name}</p>
                <p className="profile-position">{position}</p>
            </div>
        </a>
        {
                show &&
                <div ref={dropdownRef}>
                    <DropdownMenu title={name} subtitle={position} >
                        {/* <div className="notification-item">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="notification-detail">
                                    <p className="notification-info text-bold">Ubah Kata Sandi</p>
                                </div>
                            </div>
                            <hr style={{ margin: 0 }} />
                        </div>
                        <div className="notification-item">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="notification-detail">
                                    <p className="notification-info text-bold">Unsubscribe</p>
                                </div>
                            </div>
                            <hr style={{ margin: 0 }} />
                        </div> */}
                        <div className="notification-item">
                            <div style={{ display: "flex", alignItems: "center" }} onClick={() => logout()}>
                                <div className="notification-detail">
                                    <p className="notification-info text-bold">Logout</p>
                                </div>
                            </div>
                        </div>
                    </DropdownMenu>
                </div>
            }
      </li>
    </ul>
  );
};

export default Index;
