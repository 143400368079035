import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
// import ButtonModal from './ButtonModal';
import ImageSuccess from "../../assets/img/response/success.png"

const Index = ({title, message, show, toggle}) =>{
    return(
        <Modal isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 500 }}
                toggle={toggle}>
                <ModalHeader style={{textAlign:"center"}}className="text-extra-bold"> {title ?? "Berhasil!"}</ModalHeader>
                <ModalBody>
                    <div style={{ padding: "0px 20px 20px 20px", textAlign:"center", lineHeight: '2rem' }}>
                        <img style={{width:250,}} src={ImageSuccess}></img>
                        {message && <p style={{ fontSize: '16px' }}>{message}</p>}
                    </div>
                </ModalBody>
        </Modal>
    )
}
export default Index;