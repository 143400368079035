import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import InputVoucherDigitalItems from "../../components/commons/promo/InputVoucherDigitalItems";

function PromoModal({ show, toggle, type, action, data, register }) {

    const title = "Upload Foto Voucher Digital"

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5"> {title} <span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader>
                {/* <p className="desc-title-form">Isi form berikut untuk mengedit promo</p> */}
                

                <ModalBody>
                    <InputVoucherDigitalItems
                        action={action}
                        register={register}
                        data={data}
                        toggle={toggle}
                    />
                </ModalBody>
                <ModalFooter />
            </Modal>
        </>
    )
}

export default PromoModal
