import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";
import React, { useState, useEffect } from "react"

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import Promo from '../../store/actions/promotion/Index'
import SuccessModal from "../../components/response/Success";
import moment from "moment";
import localization from "moment/locale/id";
import FilterModal from "./FilterWinnerModal";
import PreviewBroadcastModal from "./PreviewBroadcastModal";

import { FormControl, InputLabel, Select } from "@material-ui/core";

const Index = ({ loggingIn, match }) => {

  const [dataWinnerPromo, setDataWinnerPromo] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterCity, setFilterCity] = useState("")
  const [filterStore, setFilterStore] = useState("")
  const [bcWinnerModal, setBcWinnerModal] = useState(false);
  const [bcWinnerModal2, setBcWinnerModal2] = useState(false);
  const [bcSuccessModal, setBcSuccessModal] = useState(false);
  const [dataWinnerRow, setDataWinnerRow] = useState([]);
  const [disqModal, setDisqModal] = useState(false);
  const [showModalGenerate, setModalGenerate] = useState(false)
  const [dataPromo, setDataPromo] = useState();

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [maxPage, setMaxPage] = useState(1);
  
  const [filter, setFilter] = useState("");
  const [values, setValues] = React.useState([
    "Semua",
    "Dikonfirmasi",
    "Belum dikonfirmasi",
    "Diskualifikasi"
  ]);
  const [selected, setSelected] = useState("Semua");

  const handleChange = (event) => {
    setSelected(event.target.value);
    setFilter(event.target.value == "Semua" ? "" : event.target.value == "Dikonfirmasi" ? 1 : event.target.value == "Belum dikonfirmasi" ? 0 : 2)
}
  
  const id = match?.params?.id

  const dispatch = useDispatch();
    useEffect(() => {

      handleRefresh();

    }, [searchText, filter, filterCity, filterStore]);
  
  const handleRefresh = () => {
      new Promise((resolve) => {
          let param = {
              page: page? page: 1,
              length: 10,
              search_text: searchText,
              id: id,
              status : filter,
              city_id: filterCity,
              store: filterStore
          }
          dispatch(Promo.getWinner(param, resolve));
      })
          .then(res => {
              setDataWinnerPromo(res?.data)
              setTotalData(res?.totalCount)
              setMaxPage(Math.ceil(res?.totalCount / 10))
              handleGetPromo()
          })
  }

  const handleGetPromo = () => {
    new Promise((resolve) => {
      let param = {
          id : id,
      }
      dispatch(Promo.detail(param, resolve));
    })
      .then(res => {
         setDataPromo(res?.data)
    })
  }

  const handleSearch = (value) => {
      setSearchText(value.length > 0 ? value : "")
  }

  useEffect(() => {
    handleRefresh();
  }, [page]);

  const [filterModal, setFilterModal] = useState(false);
  const showFilterModal = () => {
    setFilterModal(!filterModal)
  }

  const handleFilter = (param) => {
    showFilterModal()
    setFilterCity(param.city)
    setFilterStore(param.store)
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "TANGGAL BROADCAST",
      accessor: "broadcasted_at",
      Cell: ({ row }) => {
        const { original } = row;
        const {broadcasted_at} = original;
        const tanggal = broadcasted_at? moment(broadcasted_at).locale("id",localization).format('L') + ', ' + moment(broadcasted_at).locale("id", localization).format("LT") : "-"
        return tanggal
      }
    },
    {
      Header: "NAMA PEMENANG",
      accessor: "full_name",
    },
    {
      Header: "NIK",
      accessor: "nik",
      Cell: ({ row }) => {
        const { original } = row;
        let nik = original.nik == null ? '-' : original.nik
        return nik
      }
    },
    {
      Header: "NO WHATSAPP",
      accessor: "phone_number",
    },
    {
      Header: "KOTA ASAL",
      accessor: "city_name",
    },
    {
      Header: "ID VOUCHER",
      accessor: "voucher_code",
    },
    {
      Header: "TOKO",
      accessor: "store",
      Cell: ({ row }) => {
        const { original } = row;
        const {store} = original;
        const toko = store? store : "-"
        return toko
      }
    },
    {
      Header: "TANGGAL KONFIRMASI",
      accessor: "confirmation_at",
      Cell: ({ row }) => {
        const { original } = row;
        const {confirmation_at} = original;
        const tanggal = confirmation_at? moment(confirmation_at).locale("id",localization).format('L') + ', ' + moment(confirmation_at).locale("id", localization).format("LT") : "-"
        return tanggal
      }
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={
              status === 0 ? 
                "Belum Dikonfirmasi" : status === 1 ? 
                "Dikonfirmasi" : "Diskualifikasi"
            }
            color={
              status === 0 ? 
                "notAvailable" : status === 1 ? 
                "successLabel" : "disqualification"
            }
            outline
          />
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { id, status } = original;
        return (

          <DetailButton>
            <MenuItem onClick={() =>handleDetailWinner(original?.customer_id, original?.promotion_id)}>
                <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            { original.status == 0 &&
              <MenuItem onClick={() => showDisqModal(original)}>
                <span style={{ fontSize: 14 }}>Diskualifikasi</span>
              </MenuItem>
            }
          </DetailButton>
        );
        
      },
    },
  ];

  const handleDetailWinner = (ids, id_promo) => {
    history.push( `/winner/detail/${id_promo}/${ids}`)
  }

  const showBcWinnerModal = (data) => {
    setBcWinnerModal(!bcWinnerModal)
    setDataWinnerRow(data ? data : [])
  }
  const showBcWinnerModal2 = (data) => {
    setBcWinnerModal2(!bcWinnerModal2)
  }
  const showBcSuccessModal = (data) => {
    setBcSuccessModal(!bcSuccessModal)
  }

  const handleBroadcast = () => {
    const callback = () => {
      handleRefresh()
      setBcWinnerModal2(!bcWinnerModal2)
      setBcWinnerModal(!bcWinnerModal)
    }
    let param = {
        id: id,
    }
    dispatch(Promo.getBroadcast(param, callback, showBcSuccessModal))
    handleRefresh()
  }

  const showDisqModal = (data) => {
    setDisqModal(!disqModal)
    setDataWinnerRow(data ? data : [])
  }

  const handleDisqualification = () => {
    const callback = () => {
        handleRefresh()
        setDisqModal(!disqModal)
    }
    console.log(dataWinnerRow);

    let param = {
      voucher_id: dataWinnerRow.voucher_id,
    }
    dispatch(Promo.disqualificationWinner(param, callback))
}

const showModalGenerateParticipant= (data) => {
  setModalGenerate(!showModalGenerate)  
}

const handleGenerate = () => {
  // history.push(`/participant/regenerate-winner/${id}`)

  const callback = () => {
    handleRefresh()
    setModalGenerate(!showModalGenerate)  
  }

  new Promise((resolve) => {
    let param = {
        id: id,
    }
    dispatch(Promo.regenerateWinner(param, resolve, callback));
  })
  .then(res => {
      history.push({
        pathname: `/participant/regenerate-winner/${id}`,
        state: {
          newData :res.data,
        } 
      })
  })
}

  return (
    
        <div className="menu-container">
        <Row>
            <Col md={3} className="sidebar-promo-col">
              <SidebarPromo active={"promo-winner"} id={id}/>
            </Col>
            <Col md={9}>
                <div>
                    <HeaderMenu title="Daftar Pemenang">
                    <Row>
                        <Col md={1}>
                            <div style={{marginTop:20, marginRight:-30}}>
                                <SearchButton
                                    placeholder="Cari pemenang / nomor wa"
                                    toggle={(value) => handleSearch(value)}
                                />
                            </div>
                        </Col>
                        <Col md={2} style={{marginRight:20}}>
                                <div className="dropdown-winner rounded-button button-white">
                                    <Select
                                        value={selected}
                                        onChange={handleChange}
                                        inputProps={{
                                        name: "voucher",
                                        id: "voucher-id"
                                        }}
                                        disableUnderline
                                        style={{ width:'100%'}}
                                    >
                                        {values.map((value, index) => {
                                        return <MenuItem value={value}>{value}</MenuItem>;
                                        })}
                                    </Select>
                                </div>
                            </Col>
                        <Col md={4} style={{marginRight:-20}}>
                            <button type="button" className="button-broadcast" onClick={()=>{showBcWinnerModal()}}>
                            {/* <i class="fas fa-broadcast-tower"></i>{" "} Broadcast Pemenang */}
                              Broadcast Pemenang
                            </button>
                        </Col>
                        <Col md={3}>
                            <button type="button" disabled={dataWinnerPromo.length > 0 ? "" : "disable"} className={dataWinnerPromo.length > 0 ? "button-generate-ulang" : "button-generate-ulang disabled"} onClick={()=>showModalGenerateParticipant()}>
                              {/* <i class="fas fa-sort"></i>{" "} Generate Ulang */}
                              Generate Ulang
                            </button>
                        </Col>
                        <Col md={2}>
                            <button type="button" className="button-filter-city" onClick={()=>showFilterModal()}>
                            {/* <button type="button" className= "button mr-2 rounded-button primary button-default" onClick={()=>showFilterModal()}> */}
                              Filter
                            </button>
                        </Col>
                    </Row>
                    </HeaderMenu>
                </div>
                <div className="content-container-side">
                    <div className="overflow">
                    <Table
                        columns={columns}
                        data={dataWinnerPromo}
                        pagination={{
                          page,
                          totalData,
                          maxPage,
                          toggle: setPage
                        }}
                    />
                    </div>
                </div>
            </Col>
        </Row>

        {/* Modal */}
        <ConfirmationModal
          show={bcWinnerModal}
          toggle={showBcWinnerModal}
          confirm={handleBroadcast}
          // data={dataPatientRow}
          icon="info"
          isConfirm={"Broadcast"}
          message = {"Apakah Anda yakin ingin melakukan broadcast pemenang promo Bulan Ini?"}
        >
        </ConfirmationModal>

        {/* cr tidak jadi dipakek
        <PreviewBroadcastModal
          show={bcWinnerModal2}
          toggle={showBcWinnerModal2}
          confirm={showBcWinnerModal}
          data={dataPromo}
        >
        </PreviewBroadcastModal> */}

        <SuccessModal 
          show={bcSuccessModal}
          toggle={showBcSuccessModal}
          title = {"Broadcast Sukses"}
          message ={["Broadcast pemenang ", <b>promo {dataPromo?.name}</b>, " berhasil"]}
        >
        </SuccessModal>

        <ConfirmationModal
            show={disqModal}
            toggle={showDisqModal}
            confirm={handleDisqualification}
            data={dataWinnerRow}
            message = {"Apakah Anda yakin ingin mendiskualifikasi pemenang?"}
            isConfirm = {"Ya, Diskualifikasi"}
            icon="info"
            isDelete
        >
        </ConfirmationModal>

        <ConfirmationModal
          show={showModalGenerate}
          toggle={showModalGenerateParticipant}
          title = {"Generate Pemenang"}
          confirm={handleGenerate}
          // data={dataPatientRow}
          isConfirm = {"Generate"}
          icon="info"
        >
          <div>
            <p>Apakah Anda yakin ingin mengenerate ulang pemenang promo <b>Bulan {dataPromo?.name}</b>?</p>
          </div>
        </ConfirmationModal>

        <FilterModal
          show={filterModal}
          toggle={showFilterModal}
          action={handleFilter}
          id={id}
        />

        </div>
  );
};

export default Index;

