import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";

class Loader extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {

        };
    }

    render() {

        const { color, loading } = this.props;

        return (
            <div style={{ position: "absolute", width: "100%", height: "100vh", top: 0, left: 0, zIndex: 1042, background: "rgba(0, 0, 0, 0.2)" }}>
                {/* <div style={{ marginLeft: "45%", marginTop: "30%" }}> */}
                <div style={{position : "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                    <SyncLoader color={color ?? "#1C37BE"} loading={loading} />
                </div>
            </div>
        )
    }
}

export default Loader