import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import Promo from '../../store/actions/promotion/Index'
import Store from '../../store/actions/store/index';

import PromoModal from "./PromoModal";
import UploadIDModal from "./UploadIDModal";
import UploadVoucherDigitalModal from "./UploadVoucherDigitalModal";
import moment from "moment";
import localization from "moment/locale/id";

const Index = ({ loggingIn }) => {

    const [dataPromo, setDataPromo] = useState([]);
    const [editPromoModal, setEditPromoModal] = useState(false);
    const [editIdVoucherPromoModal, setEditIdVoucherPromoModal] = useState(false);
    const [editVoucherDigitalPromoModal, setEeditVoucherDigitalPromoModal] = useState(false);
    const [dataPromoRow, setDataPromoRow] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const [maxPage, setMaxPage] = useState(1);
    const [outlet, setOutlet] = useState([])

    const dispatch = useDispatch();
    useEffect(() => {

      handleRefresh();

    }, [searchText]);
  
  const handleRefresh = () => {
      new Promise((resolve) => {
          let param = {
              page: page ? page : 1,
              length: 10,
              search_text: searchText
          }
          dispatch(Promo.get(param, resolve));
      })
          .then(res => {
              setDataPromo(res?.data)
              setTotalData(res?.totalCount)
              setMaxPage(Math.ceil(res?.totalCount / 10))
              handleGetTypeStore();
          })
  }
const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "")
}
  const showEditPromoModal = (data) => {
    setEditPromoModal(!editPromoModal)
    setDataPromoRow(data ? data : [])
  }
  const showEditIdVoucherPromoModal = (data) => {
    console.log("outlet", outlet)
    setEditIdVoucherPromoModal(!editIdVoucherPromoModal)
    setDataPromoRow(data ? data : [])
  }
  const showEditVoucherDigitalPromoModal = (data) => {
    setEeditVoucherDigitalPromoModal(!editVoucherDigitalPromoModal)
    setDataPromoRow(data ? data : [])
  }

  const handleEdit = (param) => {
    const callback = () => {
        handleRefresh()
        setEditPromoModal(!editPromoModal)
    }
    if (param) {
        dispatch(Promo.put(param, callback))
    }
  }

  const handleEditIdVoucher = (param) => {
    const callback = () => {
        handleRefresh()
        setEditIdVoucherPromoModal(!editIdVoucherPromoModal)
    }
    if (param) {
        dispatch(Promo.putIdVoucher(param, callback))
    }
  }

  const handleEditVoucherDigital = (param) => {
    const callback = () => {
        handleRefresh()
        setEeditVoucherDigitalPromoModal(!editVoucherDigitalPromoModal)
    }
    if (param) {
        dispatch(Promo.putVoucherDigital(param, callback))
    }
  }

  
  const handleGetTypeStore = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999
            }
            dispatch(Store.getTypeStore(param, resolve))
        })
        .then(res => {
            let outlets = []
              res?.data?.data.map((item) => {
                outlets = [...outlets, { value: item.id, label: item.name }]
            })
            setOutlet(outlets)
        })
    }

useEffect(() => {
  handleRefresh();
}, [page]);


  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "NAMA PROMO",
      accessor: "name",
    },
    {
      Header: "JUMLAH PESERTA",
      accessor: "count_participant",
      Cell: ({ row }) => {
        const { original } = row;
        const {count_participant} = original;
        const count = count_participant + " orang"
        console.log(count_participant)
        return count
      }
    },
    {
      Header: "TGL BROADCAST",
      accessor: "broadcast_at",
      Cell: ({ row }) => {
        const { original } = row;
        const {broadcast_at} = original;
        const tanggal = broadcast_at? moment(broadcast_at).locale("id",localization).format('DD MMMM YYYY') + ', ' + moment(broadcast_at).locale("id", localization).format("LT") : "-"
        return tanggal
      }
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;
        console.log(status);

        return (
          <TableStatus
            status={
              status === 0 ? 
                "Kadaluarsa" : status === 1 ? 
                "Tersedia" : "Belum Tersedia"
            }
            color={
              status === 0 ? 
                "expired" : status === 1 ? 
                "available" : "notAvailable"
            }
            outline
          />
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { id, status } = original;

        return (

          <DetailButton>
            <MenuItem onClick={() => history.push(`/promo-detail/${original?.id}`)}>
                <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            { original.status == 2 &&
              <MenuItem onClick={() => showEditPromoModal(original)}>
                <span style={{ fontSize: 14 }}>Edit</span>
              </MenuItem>
            }
              <MenuItem onClick={() => showEditIdVoucherPromoModal(original)}>
                <span style={{ fontSize: 14 }}>Upload ID Promo</span>
              </MenuItem>
              {/* <MenuItem onClick={() => showEditVoucherDigitalPromoModal(original)}>
                <span style={{ fontSize: 14 }}>Upload Voucher Digital</span>
              </MenuItem> */}
          </DetailButton>
        );
        
      },
    },
  ];

  return (
        <div className="menu-container">
          <div>
            <HeaderMenu title="Daftar Promo">
              <Row>
                <Col>
                  <SearchButton
                    placeholder="Cari promo"
                    toggle={(value) => handleSearch(value)}
                  />
                </Col>
              </Row>
            </HeaderMenu>
          </div>
          <div className="content-container-side">
            <div className="overflow">
              <Table
                columns={columns}
                data={dataPromo}
                pagination={{
                  page,
                  totalData,
                  maxPage,
                  toggle: setPage
              }}
              />
            </div>
          </div>
          {/* Modal */}
          <PromoModal
              show={editPromoModal}
              toggle={showEditPromoModal}
              action={handleEdit}
              data={dataPromoRow}
              // register={() => showRegisterModal(dataPromoRow)}
          />
          <UploadIDModal
              show={editIdVoucherPromoModal}
              toggle={showEditIdVoucherPromoModal}
              action={handleEditIdVoucher}
              data={dataPromoRow}
              outlet={outlet}
          />
          <UploadVoucherDigitalModal
              show={editVoucherDigitalPromoModal}
              toggle={showEditVoucherDigitalPromoModal}
              action={handleEditVoucherDigital}
              data={dataPromoRow}
          />
        </div>

  );
};

export default Index;

