import React, { useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
// import { withTrans } from "../../i18n/withTrans";

// ACTION REDUX
import User from "../../store/actions/user/Index";

const CreatePassword = ({ t, match }) => {
    const formData = new FormData();

    const formikRef = useRef();
    const dispatch = useDispatch();
    const [isMatch, setIsMatch] = useState(false);
    const [matchMessage, setMatchMessage] = useState(null);
    const [checkMessage, setCheckMessage] = useState(null);
    const token = match?.params?.id
    function checkPassword() {
        const values = formikRef?.current?.values;
        confirmationPassword()
        if (values?.password) {
            setCheckMessage(values?.password?.length > 7 ? "" : "Password minimal terdiri dari 8 karakter")
        } else {
            setCheckMessage(null)
        }
    }

    function confirmationPassword() {

        const values = formikRef?.current?.values;

        if (values?.password?.length > 7 && values?.confirmation_password) {
            if (values?.password === values?.confirmation_password) {
                setIsMatch(true)
                setMatchMessage('Password yang Anda masukkan sesuai')
            } else {
                setIsMatch(false)
                setMatchMessage('Password yang Anda masukkan tidak sesuai')
            }
        } else {
            setIsMatch(false)
            setMatchMessage(null)
        }
    }

    return (
        <div className="main-wrapper">
            <div className="login-container">
                <div className="side-login"></div>
                <div className="content-wrapper">
                    <p className="super-title text-extra-bold">
                        Buat Kata Sandi
                    </p>
                    <div className="form-wrapper">
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                               password: "",
                               confirmation_password: "",
                               verify_token: token
                            }}
                            onSubmit={(values, { setStatus, }) => {
                                setStatus();

                                formData.append('id', values.verify_token);
                                formData.append('password', values.confirmation_password);

                                dispatch(User.setPassword(formData));
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Input
                                        title="Kata Sandi"
                                        name="password"
                                        type="password"
                                        placeholder="Masukkan kata sandi"
                                        errors={props.errors}
                                        touched={props.touched}
                                        onKeyUp={() => checkPassword()}
                                        {...props.getFieldProps("password")}
                                    />
                                    {checkMessage && (
                                        <div
                                            className="subtitle text-medium"
                                            style={{ color:"#E01640" ,fontSize: 10, marginTop: "5px", marginBottom: 10 }}
                                        >
                                            {checkMessage}
                                        </div>
                                    )}
                                    <Input
                                        title="Konfirmasi kata sandi"
                                        name="confirmation_password"
                                        type="password"
                                        placeholder="Konfirmasi kata sandi baru"
                                        errors={props.errors}
                                        touched={props.touched}
                                        onKeyUp={() => confirmationPassword()}
                                        {...props.getFieldProps("confirmation_password")}
                                    />
                                    {matchMessage && (
                                        <div
                                            className="text-medium"
                                            style={{
                                                fontSize: 10,
                                                color: isMatch ? "#4894FE" : "#E01640",
                                                marginTop: "5px",
                                                marginBottom: 10
                                            }}
                                        >
                                            {matchMessage}
                                        </div>
                                    )}
                                    <div className="button-wrapper" style={{ marginTop: "45px" }}>
                                        <Button
                                            title="Konfirmasi"
                                            rounded
                                            type="submit"
                                            variant="primary"
                                            style={{ width: "100%", textAlign: "center" }}
                                            disabled={!isMatch}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div
                            style={{ marginTop: 100, position: "absolute", bottom: 10 }}
                            className="subtitle"
                        >
                            Copyright © 2021 Bigio
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return { user };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePassword));
