import React, { useRef, useEffect, useState, } from 'react'
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import MultiSelect from "../../forms/Select2Multi";
import UploadIcon from "../../../assets/img/gallery/image.png"
import GetCity from '../../../store/actions/promotion/Index'


const FilterLocationItems = ({ action, data, toggle, match, filterCity, filterJob }) => {
    const id = match
    const dispatch = useDispatch();
    const formikRef = useRef();
    const fileRef = useRef();
    const [dataCity, setDataCity] = useState([]);
    const [dataJob, setDataJob] = useState([]);
    
    useEffect(() => {
        getDataCity();
        getDataJob();
    }, [])

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
            .then(res => {
                let dataCity = []
                dataCity = [...dataCity, { value: "all", label: "PILIH SEMUA LOKASI" }]
                res?.data?.data.map((item) => {
                    dataCity = [...dataCity, { value: item.id, label: item.city_name }]
                })
                setDataCity(dataCity)
            })
    }

    const getDataJob = () => {
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_job(resolve, param));
        })
        .then(res => {
            let dataJob = []
            dataJob = [...dataJob, { value: "all", label: "Pilih Semua Pekerjaan"}]
            res?.data?.data.map((item)=>{
                dataJob = [...dataJob, { value: item.id+"~"+item.name, label: item.name }]
            })
            dataJob.push({value: "other~other", label: "Lainnya"})
            setDataJob(dataJob)
        })
    }

    const initial = data ? {
        // id: data?.id,
        city: filterCity ? filterCity : "",
        job: filterJob ? filterJob : ""
    } : {
        city: filterCity ? filterCity : "",
        job: filterJob ? filterJob : ""
    }

    const validation = Yup.object().shape({
        city: Yup.string().required("Pilih kota terlebih dahulu")
    })

    const initialTouched = {
        city: true,
    }

    

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                // validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    let job = ''
                    if(values.city == 'all'){
                        values.city = ''
                    }
                    if(values.job !== 'all') {
                        job = values.job
                    }
                    action(values.city, job );
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <MultiSelect
                                name="city"
                                options={dataCity}
                                title="Kota Asal"
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values.city}
                                onChange={(name, value) => {
                                    props?.setFieldValue("city", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                                
                            />
                            {/* <Select2
                                name="city"
                                options={dataCity}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.city}
                                onChange={(name, value) => {
                                    props?.setFieldValue("city", value);
                                }}
                                onBlur={props?.setFieldTouched}
                                
                            /> */}

                            <MultiSelect
                                name="job"
                                title="Pekerjaan"
                                options={dataJob}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values.job}
                                onChange={(name, value, e) => {
                                    props?.setFieldValue("job", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                                
                            />
                            
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={7}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Terapkan
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default FilterLocationItems;
