import React from "react";
import { useTable } from "react-table";
import "./table.scss"
import Pagination from "./pagination/index";


const Index = ({ 
  columns, 
  data, 
  dark, 
  hover, 
  small, 
  pagination 
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: data ? data : [{}],
  });


  return (
    <div>
    <div className="table-responsive">
      <table
        {...getTableProps()}
        className={`table ${dark && "table-dark"} ${hover && "table-hover"
          } ${small && "table-sm"}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={{ fontSize: 14 }}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {
            rows?.length < 1 && (
              <tr>
                <td colSpan={headerGroups ? headerGroups[0]?.headers?.length + 1 : 3} style={{ textAlign: "center" }}>
                  Data tidak ditemukan
                </td>
              </tr>
            )
          }
        </tbody>
      </table>

    </div>
    <div style={{ width: "100%" }}>
    {pagination && (
      <Pagination
        page={pagination?.page}
        dataTotal={data?.length}
        recordTotal={pagination?.totalData}
        maxPage={pagination?.maxPage}
        toggle={pagination?.toggle}
      />
    )}
  </div>
  </div>
  );
};

export default Index;
