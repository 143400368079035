import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import UploadIcon from "../../../assets/img/gallery/image.png"
import UploadVidioIcon from "../../../assets/img/gallery/image_vidio.png"
import addIcon from "../../../assets/img/gallery/add.png"
import Gallery from '../../../store/actions/gallery/Index'

const InputGalleryItems = ({ action, data, register, toggle }) => {
    console.log("data edit");
    console.log(process.env.REACT_APP_API_URL);

    // const [dataGallery, setDataGallery] = useState([]);

    // const dispatch = useDispatch();
    
    // useEffect(() => {
    //     handleRefresh();
    // }, []);

    // const handleRefresh = () => {
    //     new Promise((resolve) => {
    //         let param = {
    //             id: data.id,
    //         }
    //         // dispatch(Gallery.get(param, resolve));
    //     })
    //         .then(res => {
    //             setDataGallery(res?.data)
    //         })
    // }

    const formikRef = useRef();
    const formData = new FormData();
    
    const fileRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [video, setVideo] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""});    
    const [fileVideo, setFileVideo] = useState({ error: ""});    

    const initial = data ? {
        id: data?.id,
        nama_galeri: data?.name,
        image_galeri : data?.image_url,
        video_galeri : data?.video_url,
        isi_galeri: data?.content,
    } : {
        nama_galeri: "",
        image_galeri : "",
        isi_galeri: "",
        video_galeri: ""
    }

    const validation = Yup.object().shape({
        nama_galeri: Yup.string().required("Nama galeri tidak boleh kosong").nullable(),
        isi_galeri: Yup.string().required("Isi galeri tidak boleh kosong").nullable(),
        // image_galeri : Yup.mixed()
        // .test('fileFormat', 'Empty File', (value) => {
        //     if (!value) {
        //         setFile({error:"Foto tidak boleh kosong"})
        //         return false
        //     } else if((value.size/1024/1024) > 2) {
        //         setFile({error:"Ukuran foto tidak boleh lebih dari 2MB"})
        //         setImage({
        //             preview: "",
        //             raw: "",
        //             filename: ""
        //         });
        //         return false
        //     }else {
        //         setFile({error:""})
        //         return true
        //     }

        // }),
        // video_galeri: Yup.mixed()
        // .test('fileFormat', 'Empty File', (value) => {
        //     if (!value) {
        //         setFileVideo({error:"Video tidak boleh kosong"})
        //         return false
        //     } else if((value.size/1024/1024) > 3) {
        //         setFileVideo({error:"Ukuran video tidak boleh lebih dari 2MB"})
        //         setVideo({
        //             preview: "",
        //             raw: "",
        //             filename: ""
        //         });
        //         return false
        //     }else {
        //         setFileVideo({error:""})
        //         return true
        //     }

        // }),
    })

    const initialTouched = {
        nama_galeri: true,
        isi_galeri: true,
    }

    const handleRegister = () => {
        const value = formikRef.current.values
        formikRef.current.validateForm().then(
            () => {
                if (formikRef.current.isValid) {
                    register(value)
                }
            }
        );

    }

    const handleChangeImage = (e, formik) => {
        if (e.target.files.length) {
            console.log(e);
            formik.setFieldValue('image_galeri',e.target.files[0])

            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    const handleChangeVideo = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('video_galeri',e.target.files[0])

            setVideo({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    const [fieldsFoto, setFieldsFoto] = useState([{ preview: '', raw: '', filename: '' }]);
    const handleAddFoto = () => {
        const _fields = [...fieldsFoto];
        _fields.push({ preview: '', raw: '', filename: '' });
        setFieldsFoto(_fields);
    }

    const handleChangeFotoDinamis = (i, e) => {
        if (e.target.files.length) {
            if ((e.target.files[0].type).includes("image") ){
                const _fields = [...fieldsFoto];
                _fields[i].preview = URL.createObjectURL(e.target.files[0]);
                _fields[i].raw = e.target.files[0]
                _fields[i].filename = e.target.files[0].name
                setFieldsFoto(_fields);
            }else{
                alert("Harap masukkan file dengan ekstensi jpg/png!")
            }
            // formik.setFieldValue('image_galeri',e.target.files[0])
        }
    }

    const handleRemoveFoto = (i) => {
        const _fields = [...fieldsFoto];
        _fields.splice(i, 1);
        setFieldsFoto(_fields);
    }

    const [fieldsVideo, setFieldsVideo] = useState([{ video: ''}]);
    const handleAddVideo = () => {
        const _fields = [...fieldsVideo];
        _fields.push({ video: ''});
        setFieldsVideo(_fields);
    }

    const handleChangeVideoDinamis = (i, e) => {
        const _fields = [...fieldsVideo];
        _fields[i].video = e.target.value;
        setFieldsVideo(_fields);
    }

    const handleRemoveVideo = (i) => {
        const _fields = [...fieldsVideo];
        _fields.splice(i, 1);
        setFieldsVideo(_fields);
    }

    const stringToArray = (dataString) => {
        if (dataString) {
            dataString = dataString.replace(/\[/g, "");
            dataString = dataString.replace(/\]/g, "");
            dataString = dataString.replace(/\"/g, "");
            let array = dataString.split(",")
            return(array)
        }
        return("")
    }

    const [stateVideo, setStateVideo] = useState(true);
    const enterIntoArrayVideo = (data) => {
        if (stateVideo) {
            const _fields = [...fieldsVideo];
            _fields.splice(0, 1);
            data.map((item, index) => {
                _fields.push({ video: item});
            })
            setFieldsVideo(_fields);
            setStateVideo(false)
        }
    }

    const [stateImage, setStateImage] = useState(true);
    const enterIntoArrayImage = (data) => {
        if (stateImage) {
            const _fields = [...fieldsFoto];
            _fields.splice(0, 1);
            data.map((item, index) => {
                _fields.push({ preview: process.env.REACT_APP_API_URL+item, raw: '', filename: '' });
            })
            setFieldsFoto(_fields);
            setStateImage(false)
        }
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();

                    if (values.id) {
                        formData.append('id', values.id);
                    }
                    
                    formData.append('name', values.nama_galeri);
                    fieldsFoto.map((item, index) => {
                        let dataPrevious = (item.preview).replace(process.env.REACT_APP_API_URL, "")
                        formData.append('image_url[]', item.raw ? item.raw : dataPrevious);
                    })
                    fieldsVideo.map((item, index) => {
                        formData.append('video_url[]', item.video);
                    })
                    formData.append('content', values.isi_galeri);

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <Input
                                name="nama_galeri"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("nama_galeri")}
                                title="Nama Galeri"
                                placeholder="Tulis Nama Galeri"
                                type="text"
                            />
                            
                            <div className="form-group">
                                <label className="form-label">Foto</label>
                                {stringToArray(initial?.image_galeri).length > 0 ? enterIntoArrayImage(stringToArray(initial?.image_galeri)) : ""}
                                
                                {fieldsFoto.map((field, i) => {
                                    if (i == 0) {
                                        return (
                                            <Row key={i}>
                                                <Col sm={10} className="dinamic-column">
                                                    <div className="box-upload" >
                                                        <label htmlFor="upload-button">
                                                            {field.preview ? (
                                                            <img className="img-preview" src={field.preview} alt="dummy"  />
                                                            ) : (
                                                            <>
                                                                <img className="img-preview" src={UploadIcon} />
                                                            </>
                                                            )}
                                                            <div className="filename">
                                                                <p><b>Upload Foto</b></p>
                                                                <p>{field.filename ? field.filename : "jpg, png, Max 2MB"}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {fileImage.error ? (
                                                        <p class="warn-text">{fileImage.error}</p>
                                                    ) : (
                                                        <p></p>
                                                    )}
                                                    <input
                                                        type="file"
                                                        ref={fileRef}
                                                        id="upload-button"
                                                        style={{ display: "none" }}
                                                        onChange={(e)=> handleChangeFotoDinamis(i, e)}
                                                        accept="image/png, image/jpeg"
                                                        errors={props?.errors}
                                                    />
                                                </Col>
                                                <Col className="dinamic-column add-dinamic-gallery btn-foto-dinamis">
                                                    <div className="style-color" onClick={()=>handleAddFoto()}>
                                                        <img src={addIcon} className="icon"></img>
                                                    </div>
                                                </Col>  
                                            </Row>
                                        )
                                    } else {
                                        return (
                                            <Row key={i}>
                                                <Col sm={10} className="dinamic-column">
                                                    <div className="box-upload" >
                                                        <label htmlFor={"upload-button-"+i}>
                                                            {field.preview ? (
                                                            <img className="img-preview" src={field.preview} alt="dummy"  />
                                                            ) : (
                                                            <>
                                                                <img className="img-preview" src={UploadIcon} />
                                                            </>
                                                            )}
                                                            <div className="filename">
                                                                <p><b>Upload Foto</b></p>
                                                                <p>{field.filename ? field.filename : "jpg, png, Max 2MB"}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {fileImage.error ? (
                                                        <p class="warn-text">{fileImage.error}</p>
                                                    ) : (
                                                        <p></p>
                                                    )}
                                                    <input
                                                        type="file"
                                                        ref={fileRef}
                                                        id={"upload-button-"+i}
                                                        style={{ display: "none" }}
                                                        onChange={(e)=> handleChangeFotoDinamis(i, e)}
                                                        accept="image/png, image/jpeg"
                                                        errors={props?.errors}
                                                    />
                                                </Col>
                                                <Col className="dinamic-column add-dinamic-gallery btn-foto-dinamis">
                                                    <button type="button" onClick={() => handleRemoveFoto(i)} className="btn-remove">x</button>
                                                </Col> 
                                            </Row>
                                        )
                                    }
                                })}
                            </div>

                            <label className="form-label">Link Video</label>

                            {stringToArray(initial?.video_galeri).length > 0 ? enterIntoArrayVideo(stringToArray(initial?.video_galeri)) : ""}
                            {fieldsVideo.map((field, i) => {
                                if (i == 0) {
                                    return (
                                        <Row key={i}>
                                            <Col sm={10} className="dinamic-column">
                                                <input type="text" className={field?.video == "" ? "form-control form-input is-invalid" : "form-control form-input" } name="link_video" placeholder="Tulis Link Video" value={field.video} onChange={e => handleChangeVideoDinamis(i, e)} ></input>
                                            </Col>
                                            <Col className="dinamic-column add-dinamic-gallery">
                                                <div className="style-color" onClick={()=>handleAddVideo()}>
                                                    <img src={addIcon} className="icon"></img>
                                                </div>
                                            </Col>  
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={i}>
                                            <Col sm={10} className="dinamic-column">
                                                <input type="text" className={field?.video == "" ? "form-control form-input is-invalid" : "form-control form-input" } name="link_video" placeholder="Tulis Link Video" value={field.video} onChange={e => handleChangeVideoDinamis(i, e)} ></input>
                                            </Col>
                                            <Col className="dinamic-column add-dinamic-gallery">
                                                <button type="button" onClick={() => handleRemoveVideo(i)} className="btn-remove">x</button>
                                            </Col> 
                                        </Row>
                                    )
                                }
                            })}
                            <Textarea 
                                name="isi_galeri"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("isi_galeri")}
                                title="Isi"
                                placeholder="Tulis Isi"
                                type="text"
                            />
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={8}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Simpan Data
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default InputGalleryItems;
