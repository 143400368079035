import React, { useState,useEffect,useRef } from "react";
import moment from "moment";
import localization from "moment/locale/id";
import { history } from "../../utils/History";

const SidebarGallery = ({active, id, data}) => {

    const handleSidebar = (url) => {
        history.push(url);
    }

    return (
        <div className="sidebar-gallery">
          <div className="header" onClick={()=>handleSidebar("/gallery")}>
                <i className="fas fa-arrow-left"></i> Gallery
          </div>
       
          <div>
            <div className="item">
                <div>
                    <i className="material-icons-round sidebar-icon">
                        local_offer
                    </i>
                </div>
                <div>
                    <p>Nama Gallery</p>
                    <h5>{data.name}</h5>
                </div>
            </div>
            <div className="item">
                <i className="material-icons-round sidebar-icon">
                    location_on
                </i>
                <div>
                    <p>Waktu</p>
                    <h5>{moment(data.published_at).locale("id", localization).format("L")}, {moment(data.published_at).locale("id", localization).format("LT")}</h5>
                </div>
            </div>
          </div>
        
      </div>
    )
}

export default SidebarGallery;