import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { history } from "../../utils/History";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import User from '../../store/actions/user/Management'
import SuccessModal from "../../components/response/Success";

import UserModal from "./UserModal";

const Index = ({ loggingIn }) => {
  
    const [dataUser, setDataUser] = useState([]);
    const [dataUserRow, setDataUserRow] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [addSuccessModal, setAddSuccessModal] = useState(false);

    const [searchText, setSearchText] = useState("");

    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const [maxPage, setMaxPage] = useState(1);

    const dispatch = useDispatch();
    useEffect(() => {

      handleRefresh();

    }, [searchText]);

    const handleSearch = (value) => {
        setSearchText(value.length > 0 ? value : "")
    }

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                page: page ? page : 1,
                length: 10,
                search_text: searchText,
            }
            dispatch(User.get(param, resolve));
        })
            .then(res => {
                setDataUser(res?.data)
                    setTotalData(res?.totalCount)
                    setMaxPage(Math.ceil(res?.totalCount / 10))
            })
    }

    useEffect(() => {
        handleRefresh();
      }, [page]);

    const showAddUserModal = (data) => {
        setAddUserModal(!addUserModal)
    }

    const showDeleteModal = (data) => {
        setDeleteModal(!deleteModal)
        setDataUserRow(data ? data : [])
    }

    const showAddSuccessModal = (data) => {
        setAddSuccessModal(!addSuccessModal)
      }

    const handleAdd = (param) => {
        const callback = () => {
            handleRefresh()
            setAddUserModal(!addUserModal)
        }

        dispatch(User.addUser(param, callback, showAddSuccessModal));
        handleRefresh()
    };

    const handleDelete = () => {
        const callback = () => {
            handleRefresh()
            setDeleteModal(!deleteModal)
        }

        if (dataUserRow) {
            dispatch(User.deletedUser(dataUserRow, callback))
            handleRefresh()
        }
    }

    const columns = [
        {
        Header: "NO",
        accessor: "no",
        },
        {
        Header: "NAMA",
        accessor: "username",
        },
        {
        Header: "EMAIL",
        accessor: "email",
        },
        {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
            const { original } = row;

            return (
                <DetailButton>
                    <MenuItem onClick={() => showDeleteModal(original)}>
                        <span style={{ fontSize: 14 }}>Hapus</span>
                    </MenuItem>
                </DetailButton>
            );
            
        },
        },
    ];

    return (
        <div className="menu-container">
            <div>
                <HeaderMenu title="Manajemen User">
                    <div style={{marginRight:20}}>
                        <Row>
                            <Col md={1}>
                                <div style={{marginTop:20}}>
                                    <SearchButton
                                        placeholder="Cari user"
                                        toggle={(value) => handleSearch(value)}
                                    />
                                </div>
                            </Col>
                            {/* <Col md={4}>
                                <button type="button" className="button mr-2 rounded-button button-white" onClick={()=>{handleFilter()}}>
                                    Filter
                                </button>
                            </Col> */}
                            <Col md={10}>
                                <button type="button" className="button mr-2 rounded-button primary button-default" onClick={()=>{showAddUserModal()}}>
                                    <i class="fas fa-plus icon-button"></i>{' '} Tambah User
                                </button>
                            </Col>
                        </Row>
                    </div>
                </HeaderMenu>
            </div>
            <div className="content-container-side">
                <div className="overflow">
                <Table
                    columns={columns}
                    data={dataUser}
                    pagination={{
                        page,
                        totalData,
                        maxPage,
                        toggle: setPage
                    }}
                />
                </div>
            </div>

            {/* Modal */}
            <UserModal
                show={addUserModal}
                toggle={showAddUserModal}
                action={handleAdd}
                type="add"
            />

            <ConfirmationModal
                show={deleteModal}
                toggle={showDeleteModal}
                confirm={handleDelete}
                data={dataUserRow}
                message = {"Apakah Anda yakin ingin menghapus user ini?"}
                isConfirm = {"Ya, Hapus"}
                icon="info"
                isDelete
            >
            </ConfirmationModal>

            <SuccessModal 
                show={addSuccessModal}
                toggle={showAddSuccessModal}
                title = {"Data Tersimpan"}
                message = {"Aktivasi dan pemulihan password dapat dicek di email yang terdaftar."}
            >
            </SuccessModal>
        </div>

    );
};

export default Index;

