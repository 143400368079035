import React, { useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
// import { withTrans } from "../../i18n/withTrans";

// ACTION REDUX
import User from "../../store/actions/user/Index";

const ChangePassword = ({ t }) => {

    const formikRef = useRef();
    const dispatch = useDispatch();
    const [isMatch, setIsMatch] = useState(false);
    const [matchMessage, setMatchMessage] = useState(null);
    const [checkMessage, setCheckMessage] = useState(null);

    function checkPassword() {
        const values = formikRef?.current?.values;
        if (values?.password) {
            setCheckMessage(values?.password?.length > 7 ? "" : "Password minimal terdiri dari 8 karakter")
        } else {
            setCheckMessage(null)
        }
    }

    function confirmationPassword() {

        const values = formikRef?.current?.values;

        if (values?.password?.length > 7 && values?.confirmation_password) {
            if (values?.password === values?.confirmation_password) {
                setIsMatch(true)
                setMatchMessage('Password yang Anda masukkan sesuai')
            } else {
                setIsMatch(false)
                setMatchMessage('Password yang Anda masukkan tidak sesuai')
            }
        } else {
            setIsMatch(false)
            setMatchMessage(null)
        }
    }

    // const currentUser = getUser();

    // const title = currentUser ? "Reset Kata Sandi" : "Lupa Kata Sandi";
    // const subtitle = currentUser ? "Masukkan kata sandi baru untuk mereset kata sandi" : "Masukkan email terdaftar untuk  mereset kata sandi";

    return (
        <div className="main-wrapper">
            <div className="login-container">
                <div className="side-login"></div>
                <div className="content-wrapper">
                    <p className="super-title text-extra-bold">
                        {/* {title} */}
                        Lupa Kata Sandi
                    </p>
                    <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
                        {/* {subtitle} */}
                        Masukkan email terdaftar untuk  mereset kata sandi
                    </p>
                    <div className="form-wrapper">
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                               email: "",
                            }}
                            onSubmit={(values, { setStatus, }) => {
                                setStatus();
                                dispatch(User.send_email(values));
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Input
                                        title="Email"
                                        name="email"
                                        type="email"
                                        placeholder="Masukkan email anda"
                                        errors={props.errors}
                                        touched={props.touched}
                                        // onKeyUp={() => checkPassword()}
                                        {...props.getFieldProps("email")}
                                    />
                                    {checkMessage && (
                                        <div
                                            className="subtitle text-medium"
                                            style={{ fontSize: 10, marginTop: "-5px", marginBottom: 10 }}
                                        >
                                            {checkMessage}
                                        </div>
                                    )}
                                   
                                    <div className="button-wrapper" style={{ marginTop: "45px" }}>
                                        <Button
                                            title="Kirim"
                                            rounded
                                            type="submit"
                                            variant="primary button-default"
                                            style={{ width: "100%", textAlign: "center" }}
                                            // disabled={!isMatch}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div
                            style={{ marginTop: 100, position: "absolute", bottom: 10 }}
                            className="subtitle"
                        >
                            Copyright © 2021 Bigio
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return { user };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
