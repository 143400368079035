import React from 'react'
import Button from '../buttons/Button';

function ButtonModal({
    deleted,
    toggle,
    confirm,
    cancelTitle,
    confirmTitle,
    disabled,
    cancelIcon,
    confirmIcon,
    icon,
    submitType
}) {
    return (
        <div style={{ margin: '20px 10px' }}>
            <div style={{ float: 'left', lineHeight: '30px', display: 'flex' }}>
                <Button
                    variant='secondary'
                    rounded
                    title={cancelTitle}
                    onClick={toggle}
                    leftIcon={cancelIcon ?? (icon ? 'close' : null)}
                    type='button'
                />
                <Button
                    variant={deleted ? 'danger' : 'primary'}
                    rounded
                    title={confirmTitle}
                    onClick={confirm}
                    leftIcon={confirmIcon ?? (icon ? 'done' : null)}
                    type={submitType ?? (deleted ? 'button' : 'submit')}
                    disabled={disabled ?? false}
                />
            </div>
        </div>
    )
}

export default ButtonModal