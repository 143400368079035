import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";
import Button from "../../components/buttons/Button/index";
import { getUser } from "../../utils/User";
import HeaderLogo from "../../assets/img/logo/header_logo_2.png";
// import { withTrans } from "../../i18n/withTrans";

// ACTION REDUX
import Auth from "../../store/actions/auth/Index";

const Index = ({ loggingIn }) => {

  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false)


  if (getUser()) {
    history.push(`/promo`);
  }
    const handleChangePassword = (e)=>{
        history.push('/reset-password');
    }
    const handleRefresh = () => {
       
        console.log(localStorage.getItem("user_promo"))
        history.push('/promo');
        setTimeout(function () {
          window.location.reload(false);
      }, 100);
    } 
  return (
    <div className="main-wrapper">
      <div className="login-container">
        
        <div className="side-login"></div>
        <div className="content-wrapper">
            <img className="logo" src={HeaderLogo}></img>
          <p className="super-title text-extra-bold">Login</p>
          <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
            {/* {t("login.header")} */}
          </p>
          <div className="form-wrapper">
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
                setStatus();
                const callback = () => {
                  handleRefresh()
                }
                 dispatch(Auth.auth_login({ email, password }, callback));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title="Email"
                    name="email"
                    type="text"
                    placeholder="Masukkan Email"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("email")}
                  />
                  <Input
                    title="Password"
                    name="password"
                    type={visibility ? "text" : "password"}
                    placeholder="Masukkan Kata Sandi"
                    errors={props.errors}
                    touched={props.touched}
                    righticon={visibility ? "visibility_off" : "visibility"}
                    toggleicon={() => setVisibility(!visibility)}
                    {...props.getFieldProps("password")}
                  />
                  <div
                    className="login-option-container"
                    style={{ display: "flex", marginTop: 26 }}
                  >
                    <div className="form-group">
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="rememberMe"
                            value="true"
                            checked={props.values.rememberMe === true}
                            onChange={props.handleChange}
                          />
                          <label className="form-check-label">
                            {/* {t("login.rememberMe")} */}
                            Ingat Saya
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <a
                        className="link text-bold"
                        onClick={(e)=> handleChangePassword(e)}
                        style={{ fontSize: 14 }}
                      >
                        Lupa kata sandi?
                      </a>
                    </div>
                  </div>
                  <div className="button-wrapper" style={{ marginTop: "45px" }}>
                    <Button
                      title="Login"
                      rounded
                      type="submit"
                      variant="primary button-default"
                      style={{ width: "100%", textAlign: "center" }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div
              style={{ marginTop: 100, position: "absolute", bottom: 10 }}
              className="subtitle"
            >
              Copyright © 2021 PT Charoen Pokphand Indonesia
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ auth: { loggingIn } }) => {
//   return { loggingIn };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

// export default withRouter((connect(mapStateToProps, mapDispatchToProps)(Index)));
export default Index;

