import React, { useRef, useState, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";

import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import UploadIcon from "../../../assets/img/gallery/image.png"
import image_excel from "../../../assets/img/gallery/image_excel.png"


const InputPromoItems = ({ action, data, register, toggle, outlet }) => {

    const formikRef = useRef();
    const formData = new FormData();
    const fileRef = useRef();
    const voucherRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [image2, setImage2] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""});
    const [voucherImage, setVoucher] = useState({ error: ""});    
    const initial = data ? {
        id: data?.id,
        name : data.name,
    } : {
        outletx: null,
        file_excel: "",
        voucher_digital: ""
    }
    const initialTouched = {
       outletx: true,
    }
    const outlets = outlet ? outlet : [] 
console.log("outlets",outlets)
    const validationFile = (file) => {
        if (file.includes('.xls') || file.includes('.xlsx')) {
            return false
        }
        return true
    };

    const validationImage = (file) => {
        if (file.includes('.jpg') || file.includes('.jpeg') || file.includes('.png')) {
            return false
        }
        return true
    };

    const validationFormik = (file) => {
        if (typeof file !== 'object') {
            if (file.includes('cpi-promo')) {
                return true
            }
        }
        return false
    };

    const validation = Yup.object().shape({
        outletx :Yup.string().required("Toko tidak boleh kosong").nullable(),
        file_excel : Yup.mixed()
        .test('fileFormat', 'Empty File', (value) => {
            console.log("excel", value)
            if (!value) {
                setFile({error:"File tidak boleh kosong"})
                return false
            } else if (validationFormik(value)){
                setFile({error:"Silahkan unggah file terlebih dahulu"})
                return false
            } else if(validationFile(value.name)){
                setFile({error:"File harus berformat .xls atau .xlsx"})
                return false
            }else {
                setFile({error:""})
                return true
            }

        }),
        voucher_digital : Yup.mixed()
        .test('fileFormat', 'Empty File', (value) => {
            console.log("voucher digital",value)
            if (!value) {
                setVoucher({error:"File tidak boleh kosong"})
                return false
            } else if (validationFormik(value)){
                setVoucher({error:"Silahkan unggah file terlebih dahulu"})
                return false
            } else if(validationImage(value.name)){
                setVoucher({error:"File harus berformat .jpg, .jpeg atau .png"})
                return false
            }else {
                setVoucher({error:""})
                return true
            }

        }),
    })

    const handleChangeImage = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('file_excel',e.target.files[0])
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    const handleChangeVoucher = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('voucher_digital',e.target.files[0])
            setImage2({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    if (values.id) {
                        formData.append('id',values.id)
                    }
                    formData.append('outlet_type_id',values.outletx)
                    formData.append('excel_file',values.file_excel)
                    formData.append('voucher_image',values.voucher_digital)

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            {/* <div className="form-group" style={{marginBottom:10}}>
                                <div  className="input-wrapper">
                                    <label className='form-label'>Toko</label> */}
                                    <Select2
                                        title="Toko"
                                        name="outletx"
                                        options={outlets}
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        value={props?.values?.outletx}
                                        onChange={(name, value) => {
                                            props?.setFieldValue("outletx", value);
                                        }}
                                        onBlur={props?.setFieldTouched}
                                    />
                                {/* </div>
                            </div> */}
                            
                            <div className="form-group">
                                <label className="form-label">Upload File</label>
                                <div className="box-upload" >
                                <label htmlFor="upload-button">
                                    {image.preview ? (
                                    <img className="img-preview" src={image_excel} alt="dummy"  />
                                    ) : (
                                    <>
                                        <img className="img-preview" src={initial.file_excel ? image_excel : UploadIcon} />
                                    </>
                                    )}
                                    <div className="filename">
                                        <p><b>File Excel</b></p>
                                        <p>{ image.filename ? image.filename : initial.file_excel ? initial.name+'.xlxs' :"Format .xlxs"}</p>
                                    </div>
                                </label>
                                </div>
                                {fileImage.error ? (
                                    <p class="warn-text error-message">{fileImage.error}</p>
                                ) : (
                                    <p></p>
                                )}
                                <input
                                    type="file"
                                    ref={fileRef}
                                    id="upload-button"
                                    style={{ display: "none" }}
                                    onChange={(e)=> handleChangeImage(e, props)}
                                    accept=".xls,.xlsx"
                                    errors={props?.errors}
                                />
                            </div>
                            <div className="form-group">
                                <div className="box-upload" >
                                    <label htmlFor="upload-button2">
                                        {image2.preview ? (
                                            <img className="img-preview" src={image2.preview} alt="dummy"  />
                                        ) : (
                                        <>
                                            {/* <img className="img-preview" src={UploadIcon} /> */}
                                            <img className="img-preview" src={initial.voucher_digital ? initial.voucher_digital : UploadIcon} />
                                        </>
                                        )}
                                        <div className="filename">
                                            <p><b>Unggah Foto</b></p>
                                            <p>{ image2.filename ? image2.filename : initial.voucher_digital ? initial.name+'.jpg' :"Format jpg, jpeg, png"}</p>
                                        </div>
                                    </label>
                                </div>
                                {voucherImage.error ? (
                                    <p class="warn-text error-message">{voucherImage.error}</p>
                                ) : (
                                    <p></p>
                                )}
                                <input
                                    type="file"
                                    ref={voucherRef}
                                    id="upload-button2"
                                    style={{ display: "none" }}
                                    onChange={(e)=> handleChangeVoucher(e, props)}
                                    accept=".jpg,.jpeg,.png"
                                    errors={props?.errors}
                                />
                            </div>
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={8}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Upload File
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default InputPromoItems;
