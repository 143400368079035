import API from "../API";
import { actionPending, actionSuccess, actionError } from "../action_types/index";
import { toastError, toastSuccess } from "../../../components/commons/toast";
import axios from "axios";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_GALLERY_PENDING = "GET_GALLERY_PENDING";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_ERROR = "GET_GALLERY_ERROR";
export const POST_GALLERY_PENDING = "POST_GALLERY_PENDING";
export const POST_GALLERY_SUCCESS = "POST_GALLERY_SUCCESS";
export const POST_GALLERY_ERROR = "POST_GALLERY_ERROR";
export const DELETE_GALLERY_PENDING = "DELETE_GALLERY_PENDING";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_ERROR = "DELETE_GALLERY_ERROR";
export const UPDATE_GALLERY_PENDING = "UPDATE_GALLERY_PENDING";
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_ERROR = "UPDATE_GALLERY_ERROR";
export const GET_DETAIL_GALLERY_PENDING = "GET_DETAIL_GALLERY_PENDING";
export const GET_DETAIL_GALLERY_SUCCESS = "GET_DETAIL_GALLERY_SUCCESS";
export const GET_DETAIL_GALLERY_ERROR = "GET_DETAIL_GALLERY_ERROR";

export const GALLERY_DATA = 'GALLERY_DATA';

const GALLERY_LIST_URL = "/gallery"
const GALLERY_DETAIL_URL = "/gallery/detail"
const GALLERY_UPDATE_URL = "/gallery/update"
const GALLERY_CREATE_URL = "/gallery/create"
const GALLERY_DELETE_URL = "/gallery/delete"

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_GALLERY_PENDING));
    API.post(GALLERY_LIST_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_GALLERY_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.recordsTotal;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_GALLERY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const postGallery = (param, callback) => (dispatch) => {
    dispatch(actionPending(POST_GALLERY_PENDING));
    API.post(GALLERY_CREATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_GALLERY_SUCCESS, res));
            toastSuccess("Berhasil menambahkan data");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_GALLERY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const deletedGallery = (param, callback) => (dispatch) => {
    dispatch(actionPending(DELETE_GALLERY_PENDING));
    API.post(GALLERY_DELETE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_GALLERY_SUCCESS, res));
            if (callback) {
                callback();
            }
            toastSuccess("Berhasil menghapus data");
        })
        .catch((err) => {
            dispatch(actionError(DELETE_GALLERY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const updateGallery = (param, callback) => (dispatch) => {
    dispatch(actionPending(UPDATE_GALLERY_PENDING));
    API.post(GALLERY_UPDATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(UPDATE_GALLERY_SUCCESS, res));
            toastSuccess("Berhasil update data");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(UPDATE_GALLERY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const getDetailGallery = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_DETAIL_GALLERY_PENDING));
    API.post(GALLERY_DETAIL_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_DETAIL_GALLERY_SUCCESS, res));
            if (resolve){
                let data = res.data.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_DETAIL_GALLERY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const Gallery = { get, postGallery, deletedGallery, updateGallery, getDetailGallery};
export default Gallery;


