import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import Promo from '../../store/actions/promotion/Index'
import moment from "moment";
import localization from "moment/locale/id";



const Index = ({ loggingIn, match }) => {

  const [dataPromo, setDataPromo] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(5);
  const [maxPage, setMaxPage] = useState(1);
  const [dataVoucher, setDataVoucher] = useState([]);
  console.log(dataPromo)
  const dispatch = useDispatch();
  const id = match?.params?.id
  // const [editModal, setEditModal] = useState(false);
  useEffect(() => {
    
    handleRefresh();
  }, []);
  const handleRefresh = () => {
    new Promise((resolve) => {
        let param = {
            id: id,
        }
        dispatch(Promo.detail(param, resolve));
    })
        .then(res => {
            setDataPromo(res?.data)
            let listOutlet = []
            res?.data?.outlet.map((item, index) => {
                listOutlet = [...listOutlet, 
                    { 
                        no: index + 1,
                        id: item.id, 
                        name: item.name,
                        voucher_image: process.env.REACT_APP_API_URL + item.voucher_image,
                        file_url: process.env.REACT_APP_API_URL + item.file_url
                    }
                ]
            })
            setDataVoucher(listOutlet)
        })
    }
    
    const status = dataPromo?.status == 1 ? "Tersedia": dataPromo.status == 0? "Kadaluarsa" : "Belum Tersedia" 

    const checkLengthString = (valStr) => {
        if (valStr.length > 60){
            return valStr.substring(0, 60).trimEnd() + "..."
        }
        return valStr
    }

    const columns = [
        {
          Header: "NO",
          accessor: "no",
        },
        {
          Header: "NAMA TOKO",
          accessor: "name",
        },
        {
            Header: "FOTO VOUCHER",
            accessor: "voucher_image",
            Cell: ({ row }) => {
                const { original } = row;
                return (
                    <a href={original?.voucher_image} target="_blank">Lihat Foto</a>
                );
            },
        },
        {
            Header: "LINK EXCEL",
            accessor: "file_url",
            Cell: ({ row }) => {
                const { original } = row;
                return (
                    <a href={original?.file_url} target="_blank">{checkLengthString(original?.file_url)}</a>
                );
            },
        },
    ];

    return (
        <div className="menu-container">
        <Row>
            <Col md={3} className="sidebar-promo-col">
              <SidebarPromo active={"promo-detail"} id={id}/>
            </Col>
            <Col md={9}>
                <div>
                    <HeaderMenu title="Detail Promo">
                    </HeaderMenu>
                </div>
                <div className="content-container-side">
                    <div className="box-rounded">
                        {dataPromo ? (
                            <div>
                                <table id="table-detail-promo">
                                    {/* <tr><th>ID Promo</th><td><a href={process.env.REACT_APP_API_URL + dataPromo.file_url} className={dataPromo.file_url ? "" : "disabled-voucher"}>{dataPromo.file_url ? `Promo Bulan ${dataPromo.name}.xlsx` : '-'}</a></td></tr> */}
                                    <tr><th>Nama Promo</th><td>{dataPromo.name}</td></tr>
                                    <tr><th>Status</th><td>{status}</td></tr>
                                    <tr><th>Tanggal Berlaku</th><td>{moment(dataPromo.start_date).locale("id", localization).format("DD MMMM YYYY")} - {moment(dataPromo.end_date).locale("id", localization).format("DD MMMM YYYY")}</td></tr>
                                    <tr><th>Jumlah Peserta</th><td>{dataPromo.count_participant} orang</td></tr>
                                    <tr><th>Jumlah Voucher</th><td>{dataPromo.current_voucher}</td></tr>
                                    <tr><th>Radius Lokasi Promo</th><td>{dataPromo.radius} km</td></tr>
                                    <tr><th>Jumlah Peserta per Radius</th><td>{dataPromo.max_participant_per_radius} orang</td></tr>
                                    <tr><th>Link Promo</th><td><a href={process.env.REACT_APP_WEB_URL+"promo/01/"+ String(dataPromo?.start_date).substring(0,4) +"/" + dataPromo.code} target="_blank">{process.env.REACT_APP_WEB_URL}promo/01/{String(dataPromo?.start_date).substring(0,4)}/{dataPromo.code}</a></td></tr>
                                    <tr><th>Toko</th><td>{dataPromo.store}</td></tr>
                                    {/* <tr>
                                        <th>Foto Voucher</th>
                                        <td>
                                            {dataPromo.voucher_image ? (
                                                <img className="img-preview-detail" src={process.env.REACT_APP_API_URL + dataPromo.voucher_image} />
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr> */}
                                    {/* <tr><th>Deskripsi Voucher</th><td>{dataPromo.description}</td></tr> */}
                                    <tr>
                                        <th>Twibbon Foto</th>
                                        <td>
                                            {dataPromo.twibbon_url ? (
                                                <img className="img-preview-detail" src={process.env.REACT_APP_API_URL + dataPromo.twibbon_url} />
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                </table>
                                <div className="content-container-side" style={{padding:'0px'}}>
                                    <div className="overflow">
                                    <Table
                                        columns={columns}
                                        data={dataVoucher}
                                    />
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </div>
            </Col>
        </Row>
        </div>
    );
};

export default Index;

