
const Index = ({ title, subtitle, children }) => {

    return (

        <div className="notification-menu">
            <div className="notification-open">
                {title &&
                    <div className="notification-title text-extra-bold">
                        {title}
                    </div>
                }
                {subtitle &&
                    <div className="notification-subtitle">
                        {subtitle}
                    </div>
                }
            </div>
            <hr />
            {children}
        </div>
    )
}

export default Index;