import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { history } from "../../utils/History";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import Gallery from '../../store/actions/gallery/Index'

import GalleryModal from "./GalleryModal";
import moment from "moment";
import localization from "moment/locale/id";

const Index = ({ loggingIn }) => {

  
    const [dataGallery, setDataGallery] = useState([]);
    const [editGalleryModal, setEditGalleryModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [dataGalleryRow, setDataGalleryRow] = useState([]);

    
    const [addGalleryModal, setAddGalleryModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);

    const [searchText, setSearchText] = useState("");
    

    const dispatch = useDispatch();
    useEffect(() => {

      handleRefresh();

    }, [searchText]);

    const handleSearch = (value) => {
        setSearchText(value.length > 0 ? value : "")
    }

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 50,
                search_text: searchText
            }
            dispatch(Gallery.get(param, resolve));
        })
            .then(res => {
                setDataGallery(res?.data)
            })
    }

    const showEditGalleryModal = (data) => {
        setEditGalleryModal(!editGalleryModal)
        setDataGalleryRow(data ? data : [])
    }

    const showDeleteModal = (data) => {
        setDeleteModal(!deleteModal)
        setDataGalleryRow(data ? data : [])
    }

    const showAddGalleryModal = (data) => {
        setAddGalleryModal(!addGalleryModal)
    }

    const showRegisterModal = (data) => {
        setRegisterModal(!registerModal)
        // setDataPatientRow(data ? data : [])
    }

    const handleAdd = (param) => {
        const callback = () => {
            handleRefresh()
            setAddGalleryModal(!addGalleryModal)
        }

        dispatch(Gallery.postGallery(param, callback));
        handleRefresh()
    };

    const handleDelete = () => {
        const callback = () => {
            handleRefresh()
            setDeleteModal(!deleteModal)
        }

        if (dataGalleryRow) {
            const id = dataGalleryRow?.id;
            dispatch(Gallery.deletedGallery(dataGalleryRow, callback))
            handleRefresh()
        }
    }

    const handleEdit = (param) => {
        const callback = () => {
            handleRefresh()
            setEditGalleryModal(!editGalleryModal)
        }

        if (param) {
            dispatch(Gallery.updateGallery(param, callback))
        }
    }

    const handleAddRegister = (param) => {
        const callback = () => {
            handleRefresh()
            setAddGalleryModal(!addGalleryModal)
        }

        // new Promise((resolve) => {
        //     dispatch(PatientData.post(param, callback, resolve));
        // })
        //     .then(res => {
        //         showRegisterModal(res?.data)
        //     })
    }

    const columns = [
        {
        Header: "No",
        accessor: "no",
        },
        {
        Header: "NAMA GALERI",
        accessor: "name",
        },
        {
        Header: "TANGGAL POSTING",
        accessor: "published_at",
        Cell: ({ row }) => {
            const { original } = row;
            const {published_at} = original;
            const tanggal = moment(published_at).locale("id", localization).format("L") + ', ' + moment(published_at).locale("id", localization).format("LT")
            return tanggal
          }
        },
        {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
            const { original } = row;
            const { id, status } = original;

            return (

            <DetailButton>
                <MenuItem onClick={() => history.push(`/gallery-detail/${original?.id}`)}>
                    <span style={{ fontSize: 14 }}>Detail</span>
                </MenuItem>
                <MenuItem onClick={() => showEditGalleryModal(original)}>
                    <span style={{ fontSize: 14 }}>Edit</span>
                </MenuItem>
                <MenuItem onClick={() => showDeleteModal(original)}>
                    <span style={{ fontSize: 14 }}>Hapus</span>
                </MenuItem>
            </DetailButton>
            );
            
        },
        },
    ];

    return (
        <div className="menu-container">
            <div>
                <HeaderMenu title="Galeri">
                <Row>
                    <Col md={1}>
                        <div style={{marginTop:20}}>
                            <SearchButton
                                placeholder="Cari galeri"
                                toggle={(value) => handleSearch(value)}
                            />
                        </div>
                    </Col>
                    <Col md={10}>
                        <button type="button" className="button mr-2 rounded-button primary button-default" onClick={()=>{showAddGalleryModal()}}>
                            <i class="fas fa-plus icon-button"></i>{' '} Tambah Galeri
                        </button>
                    </Col>
                </Row>
                </HeaderMenu>
            </div>
            <div className="content-container-side">
                <div className="overflow">
                <Table
                    columns={columns}
                    data={dataGallery}
                />
                </div>
            </div>

            {/* Modal */}
            <GalleryModal
                show={addGalleryModal}
                toggle={showAddGalleryModal}
                action={handleAdd}
                type="add"
                // register={(param) => handleAddRegister(param)}
            />

            <GalleryModal
                show={editGalleryModal}
                toggle={showEditGalleryModal}
                action={handleEdit}
                data={dataGalleryRow}
                // register={() => showRegisterModal(dataGalleryRow)}
            />
            
            <ConfirmationModal
                show={deleteModal}
                toggle={showDeleteModal}
                confirm={handleDelete}
                data={dataGalleryRow}
                message = {"Apakah Anda yakin ingin menghapus galeri ini?"}
                isConfirm = {"Ya, Hapus"}
                icon="info"
                isDelete
            >
            </ConfirmationModal>

        </div>

    );
};

export default Index;

