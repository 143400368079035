import API from "../API";
import { actionPending, actionSuccess, actionError } from "../action_types/index";
import { toastError, toastSuccess } from "../../../components/commons/toast";
import axios from "axios";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_VOUCHER_PENDING = "GET_VOUCHER_PENDING";
export const GET_VOUCHER_SUCCESS = "GET_VOUCHER_SUCCESS";
export const GET_VOUCHER_ERROR = "GET_VOUCHER_ERROR";
export const GET_DETAIL_VOUCHER_PENDING = "GET_DETAIL_VOUCHER_PENDING";
export const GET_DETAIL_VOUCHER_SUCCESS = "GET_DETAIL_VOUCHER_SUCCESS";
export const GET_DETAIL_VOUCHER_ERROR = "GET_DETAIL_VOUCHER_ERROR";
export const POST_CLAIM_VOUCHER_PENDING = "POST_CLAIM_VOUCHER_PENDING";
export const POST_CLAIM_VOUCHER_SUCCESS = "POST_CLAIM_VOUCHER_SUCCESS";
export const POST_CLAIM_VOUCHER_ERROR = "POST_CLAIM_VOUCHER_ERROR";

export const VOUCHER_DATA = 'VOUCHER_DATA';

const VOUCHER_LIST_URL = "/voucher"
const VOUCHER_DETAIL_URL = "/voucher/detail"
const CLAIM_VOUCHER_URL = "/voucher/claim"

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_VOUCHER_PENDING));
    API.post(VOUCHER_LIST_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_VOUCHER_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_VOUCHER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const getDetailVoucher = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_DETAIL_VOUCHER_PENDING));
    API.post(VOUCHER_DETAIL_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_DETAIL_VOUCHER_SUCCESS, res));
            if (resolve){
                let data = res.data.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_DETAIL_VOUCHER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const claimVoucher = (param, callback) => (dispatch) => {
    dispatch(actionPending(POST_CLAIM_VOUCHER_PENDING));
    API.post(CLAIM_VOUCHER_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_CLAIM_VOUCHER_SUCCESS, res));
            toastSuccess("Berhasil Claim Voucher");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_CLAIM_VOUCHER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const Gallery = { get, getDetailVoucher, claimVoucher};
export default Gallery;


