import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import Input from "../../../src/components/forms/Input/index";

function PreviewBroadcastModal({ show, toggle, confirm, data }) {
const dataPromo = data ? data : null

    return (
        <>
            <Modal size="lg" style={{maxWidth: '800px', width: '800px'}} isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5">Broadcast Pemenang<span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader>
                <ModalBody>
                <div className="form-group" style={{marginBottom:10}}>
                    <div  className="input-wrapper">
                        <label className='form-label'>Toko</label>
                        <input className='form-control form-input'
                                    name="toko"
                                    title="Toko"
                                    placeholder="Masukkan nama toko (Contoh: PFM)"
                                    type="text"
                                    disabled
                                    value={dataPromo?dataPromo.store:"-"}
                        />
                    </div>
                </div>
                <div className='form-group'style={{marginBottom:10,textAlign:"left"}}>
                    <div  className="input-wrapper">
                        <img style={{height:"200px",width: "auto",maxWidth:"400px",marginBottom: "10px"}} src={ dataPromo? process.env.REACT_APP_API_URL + dataPromo.voucher_image : "" }>
                        </img>
                    </div>
                </div>
                <p>
                    Selamat anda telah terpilih menjadi pemenang promo undian CPI bulan {dataPromo?dataPromo.name:""}</p>

                 <p>   Terima kasih atas kontribusi Anda dalam program Undian Promo Charoen Pokphand Indonesia. Dengan ikut serta dalam program ini, Anda telah mendukung kami untuk dapat memberikan pelayanan yang lebih baik lagi bagi Anda sebagai pelanggan setia PT Charoen Pokphand Indonesia 
                </p>
                   
                </ModalBody>
                <ModalFooter>
                        {/* <Row style={{marginTop: 20, justifyContent:"end"}}> */}
                                            <div style={{justifyContent:"end"}}>
                                                <button type="button" className="button-batal" onClick={toggle}>
                                                    Batal
                                                </button>
                                                <button type="submit" onClick={confirm} className="button-simpan">
                                                Lanjutkan
                                                </button>
                                            </div>
                            {/* </Row> */}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default PreviewBroadcastModal
