import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/History";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast/index";
import { getUser } from "../../../utils/User";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_AUTH_LOGIN_PENDING = "POST_AUTH_LOGIN_PENDING";
export const POST_AUTH_LOGIN_SUCCESS = "POST_AUTH_LOGIN_SUCCESS";
export const POST_AUTH_LOGIN_ERROR = "POST_AUTH_LOGIN_ERROR";

// URL: URL_{URL}
const AUTH_LOGIN_URL = `${process.env.REACT_APP_API_URL}/authentications/login`;

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));

const auth_login = (param,callback) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_LOGIN_PENDING));
  axios
    .post(AUTH_LOGIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
      localStorage.setItem("user_promo", JSON.stringify(res.data.data));
      if (callback) {
        callback();
    }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_LOGIN_ERROR));
      toastError("Periksa kembali email dan password Anda");
    });
};

const logout = () => {
  if (getUser() && getUser() !== null) {
    localStorage.removeItem("user_clinic");
    localStorage.removeItem("persist:root");
    history.push("/login");
  }
};


const Auth = {
  auth_login,
  logout,
};
export default Auth;
