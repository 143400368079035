import React, { useState,useEffect,useRef } from "react";
import moment from "moment";
import localization from "moment/locale/id";
import { history } from "../../utils/History";

const SidebarGallery = ({active, id, data}) => {

    const handleSidebar = (url) => {
        history.push(url);
    }

    return (
        <div className="sidebar-gallery">
          <div className="header" onClick={()=>handleSidebar("/voucher")}>
                <i className="fas fa-arrow-left"></i> Voucher
          </div>
       
          <div>
            <div className="item">
                <div>
                    <i className="material-icons-round sidebar-icon">
                        local_offer
                    </i>
                </div>
                <div>
                    <p>Nama Promo</p>
                    <h5>Promo {data.nama_promo}</h5>
                </div>
            </div>
            <div className="item">
                <i className="material-icons-round sidebar-icon">
                    confirmation_number
                </i>
                <div>
                    <p>ID Barcode</p>
                    <h5>{data.id_barcode}</h5>
                </div>
            </div>
            <div className="item">
                <i className="material-icons-round sidebar-icon">
                    info
                </i>
                <div>
                    <p>Status</p>
                    <h5>{data.status_klaim == 0 ? "Belum Diklaim" : "Diklaim"}</h5>
                </div>
            </div>
          </div>
        
      </div>
    )
}

export default SidebarGallery;