import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import FilterWinnerItems from "../../components/commons/promo/FilterWinnerItems";

function FilterLocationModal({ show, toggle, type, action, data, register, id }) {

    const title = "Filter Daftar Pemenang"

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5"> {title} <span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader>

                <ModalBody>
                    <FilterWinnerItems
                        action={action}
                        register={register}
                        data={data}
                        toggle={toggle}
                        match={id}
                    />
                </ModalBody>
                <ModalFooter />
            </Modal>
        </>
    )
}

export default FilterLocationModal
