import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import FilterLocationItems from "../../components/commons/promo/FilterLocationItems";

function FilterLocationModal({ show, toggle, type, action, data, register, id, filterJob, filterCity }) {

    const title = "Filter Data Peserta"

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5"> {title} <span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader>

                <ModalBody>
                    <FilterLocationItems
                        action={action}
                        register={register}
                        data={data}
                        toggle={toggle}
                        match={id}
                        filterJob={filterJob}
                        filterCity={filterCity}
                    />
                </ModalBody>
                <ModalFooter />
            </Modal>
        </>
    )
}

export default FilterLocationModal
