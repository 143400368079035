import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter ,useLocation } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import Promo from '../../store/actions/promotion/Index'
import ConfirmationModal from "../../components/modal/ConfirmModal";
import SuccessImage from "../../assets/img/response/success.png"
// import File from "../../components/forms/File/index"
import { Formik, Form,Field, FieldArray } from "formik";
import ParticipantModal from "./ParticipantModal";
import { toastSuccess, toastError } from "../../components/commons/toast/index"
import SuccessModal from "../../components/response/Success";
import readXlsxFile from 'read-excel-file'




const Index = ({ loggingIn, match }) => {
    const [newData, setNewData] = useState([]);
    const [dataTable, setDataTable] = useState();
    const [statt, setStatt] = useState("Halo");
    const [dataPromo, setDataPromo] = useState();
    const [bcWinnerModal, setBcWinnerModal] = useState(false);
    const [dataWinnerRow, setDataWinnerRow] = useState([]);
    const [bcSuccessModal, setBcSuccessModal] = useState(false);
    const [dataExcel, setDataExcel] = useState([]);
    const [dataNewId, setDataNewId] = useState([]);
    const [dataWinnerPromo, setDataWinnerPromo] = useState([]);
    const id = match?.params?.id_promo
    const formData = new FormData();
        
    const location = useLocation();

  const dispatch = useDispatch();

  const handleChangeIdVoucher = (id, values) => {
      let filtered = newData
     filtered.map((item,i)=>{
        if(item.no === id){
          item.id_voucher = values
        }
     })
    //  filtered[0].id_voucher = values
    setStatt(values)
     setNewData(filtered);
  }
  const handleChangeUpload = (id,e) => {
    if ((e.target.files[0].type).includes("image") ){
      let filtered =  newData.filter(dat => dat.no === id)
     filtered[0].upload_voucher = e.target.files[0]
     setStatt(e.target.files[0])
     setNewData(newData)
    }else{
      alert("Harap masukkan file dengan ekstensi jpg/png!")
    }
    
  }
  const handleSave = () => {
    // let valid = validation(newData);
    // if(valid == true){
    //   handleFormData()
    //   const callback = () => {
    //     history.push(`/promo-winner/${id}`)
    //   }
    //   dispatch(Promo.saveWinner(formData, callback()));
    // }
    // console.log(newData);

    handleFormData()
    const callback = () => {
      handleRefresh()
      history.push(`/promo-winner/${id}`)
    }
    dispatch(Promo.saveWinner(formData, callback()));
    
  }
  const validation = (param) => {
    let success = true
    param.map((item,index)=>{
      if (item.id_voucher === ""){
        success = false
       
        toastError("Periksa kembali ID Voucher setiap pemenang");
      }
      if (item.upload_voucher === ""){
        success = false
      
        toastError("Periksa kembali Foto Voucher yg harus diupload")
      }
    })
    return success
  }
  const handleFormData = () => {
    let data = newData
    let array = []
    data.map((item,idx)=>{
      formData.append('winner_id[]',item.id)
      formData.append('voucher_id[]',dataNewId[idx])
      // formData.append('voucher_image[]',item.upload_voucher)
      formData.append('promotion_id[]',item.promotion_id)
      array.push(item.id)
    })
  }
  const handleText = (values) => {
    setStatt(values);
  }
  useEffect(() => {
    // new Promise((resolve) => {
    //     let param = {
    //         id: id,
    //     }
    //     dispatch(Promo.regenerateWinner(param, resolve));
    // })
    //     .then(res => {
    //         console.log(res.data)
    //         setNewData(res.data)
    //         setDataTable(res.data)
    //         handleGetPromo();
    //     })
    handleUrlToFile()
    setNewData(location.state.newData)
    setDataTable(location.state.newData)
    handleGetPromo();
  }, [])
const handleRefresh = (param) => {
    console.log("handleRefresh")
    setDataTable(param)
    console.log("newDataTable",newData)
}
  useEffect(() => {
    console.log("useEffect after change")
    handleRefresh(newData)
    handleGetWinner()
  }, [newData])
const handleGetPromo = () => {
    new Promise((resolve) => {
      let param = {
          id : id,
      }
      dispatch(Promo.detail(param, resolve));
    })
      .then(res => {
         setDataPromo(res?.data)
    })
  }
const handleGetWinner = () => {
  new Promise((resolve) => {
    let param = {
        page: 1,
        length: 10000,
        id: id,
    }
    dispatch(Promo.getWinner(param, resolve));
  })
  .then(res => {
      setDataWinnerPromo(res?.data)
  })
}
const handleBack = () => {
   history.push(`/promo-winner/${id}`)
}
const showBcWinnerModal = (data) => {
  setBcWinnerModal(!bcWinnerModal)
  setDataWinnerRow(data ? data : [])
}
const handleBroadcast = () => {
  // let valid = validation(newData);
  // if(valid == true){
  //   handleFormData()
  //   const callback = () => {
  //     handleRefresh()
  //     setBcWinnerModal(!bcWinnerModal)
  //     showBcSuccessModal()
  //     handleRedirectPemenang();
      
  //   }
  //   dispatch(Promo.saveBroadcast(formData, callback));
  // }
  // console.log(newData);

  handleFormData()
    const callback = () => {
      handleRefresh()
      setBcWinnerModal(!bcWinnerModal)
      showBcSuccessModal()
      handleRedirectPemenang();
      
    }
  dispatch(Promo.saveBroadcast(formData, callback));
  
}
const showBcSuccessModal = () => {
  setBcSuccessModal(!bcSuccessModal)
}
const handleRedirectPemenang = () => {
  handleRefresh()
  setTimeout(() => {
      history.push(`/promo-winner/${id}`)
    }, 5000)
}

const handleUrlToFile = () => {
  if (dataExcel.length == 0) {
    fetch(process.env.REACT_APP_API_URL + dataPromo?.file_url)
      .then((response) => {
          response.blob().then((fileBlob) => { 
              let file = new File([fileBlob], "Sample.xlsx");
              readXlsxFile(file).then((rows) => { 
                  var dataIdDisq = [];
                  let dataId = [];
                  for (var j = 0; j < dataWinnerPromo.length; j++) {
                    if (dataWinnerPromo[j].status == 0 || dataWinnerPromo[j].status == 1) {
                      dataId.push(dataWinnerPromo[j].voucher_code)
                    }
                  }

                  for (var i = 1; i < rows.length; i++) {
                    if (!dataId.includes(rows[i][1])) {
                      dataIdDisq.push(rows[i][1])
                    }
                  }
                  setDataExcel(rows)
                  setDataNewId(dataIdDisq)
              })
          })
      })
  }
}

  return (
    <div className="menu-container">
        <div className="header-generate-result">
            <div className="title"><b> Hasil Generate Pemenang</b></div>
            <p>Hasil generate pemenang bulan {dataPromo?.name} </p>
          
            <img className="img-generate-result" src={SuccessImage}></img>
        </div>
      
            <div className="content-container-side">
                <div className="overflow">
                    {/* <Form> */}
                        {/* <Table className="borderd" columns={columns} data={dataTable}> */}

                        {/* </Table> */}
                    {/* </Form> */}
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                            <th>No</th>
                            <th>NAMA PEMENANG</th>
                            <th>NO WHATSAPP</th>
                            <th>ID VOUCHER</th>
                            {/* <th>UPLOAD VOUCHER</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(dataNewId)}
                        {dataPromo?.file_url && handleUrlToFile()}
                        {newData && 
                          newData.map((item,idx)=>{
                            return(
                              <tr>
                                <td>{item.no}</td>
                                <td>{item.full_name}</td>
                                <td>{item.phone_number}</td>
                                <td>{dataNewId[idx] && dataNewId[idx]}</td>
                                {/* <td>
                                  <input type="text" className={item?.id_voucher == "" ? "form-control form-input is-invalid" : "form-control form-input" } name="[id_voucher]" onChange={(e)=>handleChangeIdVoucher(item?.no, e.target.value)} value={dataExcel[idx+1] && dataExcel[idx+1][1]}></input>
                                </td> */}
                                {/* <td>
                                <div class="input-group">
                                    <input type="file" className={item?.upload_voucher == "" ? "form-control is-invalid  form-input upload-voucher" : "form-control  form-input upload-voucher" } name="[upload_voucher]" onChange={(e)=>handleChangeUpload(item?.no, e)} accept="image/*"></input>
                                    <div class="input-group-append">
                                    <span class="input-group-text append-upload-voucher"><i class="fa fa-upload"></i></span>
                                        
                                    </div>
                                </div> */}
                                  {/* <input type="file" className="form-control form-input upload-voucher" name="[upload_voucher]" onChange={(e)=>handleChangeUpload(item?.no, e)}></input> */}
                                {/* </td> */}
                              </tr>
                              
                            ) 
                          })
                        }
                      </tbody>
                      
                    
                    </table>
                  </div>
                    <div className="button-generated-result">
                             <button type="button" className="button-batal" onClick={()=>handleBack()}>
                                        Batal Generate
                             </button>
                             <button type="submit" className="button-simpan"onClick={()=>{showBcWinnerModal()}} >
                                   Broadcast
                              </button>
                             <button type="submit" onClick={()=>handleSave()} className="button-simpan">
                                   Simpan
                              </button>
                    </div>
                </div>
            </div>
            <ConfirmationModal
              show={bcWinnerModal}
              toggle={showBcWinnerModal}
              confirm={handleBroadcast}
              // data={dataPatientRow}
              icon="info"
              isConfirm={"Broadcast"}
              message = {`Apakah Anda yakin ingin melakukan broadcast pemenang promo Bulan ${dataPromo?.name}?`}
            >
        </ConfirmationModal>
        <SuccessModal 
          show={bcSuccessModal}
          toggle={showBcSuccessModal}
          title = {"Broadcast Sukses"}
          message = {`Generate Pemenang Promo Bulan ${dataPromo?.name} Berhasil!`}
        >
        </SuccessModal>
    </div>
  );
};

export default Index;

