import React, { Component } from 'react';
import { ErrorMessage } from 'formik';
import "../forms.scss"

export default class File extends Component {

    render() {

        return (
            <div>
               <label className="file-label" for="input-file">Browse...</label>
                <input className="upload-file" type="file" name="file" id="input-file" />
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
            </div>
        );
    }
}