import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { history } from "../../utils/History";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
// import MenuItem from "@material-ui/core/MenuItem";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import Voucher from '../../store/actions/voucher/Index'

import VoucherModal from "./VoucherModal";
import moment from "moment";
import localization from "moment/locale/id";

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const Index = ({ loggingIn }) => {

    const [dataVoucher, setDataVoucher] = useState([]);
    const [dataVoucherRow, setDataVoucherRow] = useState([]);
    
    const [claimVoucherModal, setClaimVoucherModal] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState("");
    const [countFilter, setCountFilter] = useState(0);

    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const [maxPage, setMaxPage] = useState(1);

    const [values, setValues] = React.useState([
        "Semua",
        "Diklaim",
        "Belum diklaim"
      ]);
    const [selected, setSelected] = useState("Semua");

    const handleChange = (event) => {
        setSelected(event.target.value);
        setFilter(event.target.value == "Semua" ? "" : event.target.value == "Diklaim" ? "diklaim" : "belum diklaim")
    }
    
    // const dataVoucher = [
    //     {
    //       "no" : "1",
    //       "id_voucher" : "8274ebd71",
    //       "nama_pemenang" : "Suhendra Darmawan",
    //       "no_wa" : "0854637264432",
    //       "promo_name" : "Promo Januari",
    //       "id_kasir" : "1234432",
    //       "status" : 0,
    //     },
    //     {
    //       "no" : "2",
    //       "id_voucher" : "8274ebd71",
    //       "nama_pemenang" : "Suhendra Darmawan",
    //       "no_wa" : "0854637264432",
    //       "promo_name" : "Promo februari",
    //       "id_kasir" : "1234432",
    //       "status" : 0,
    //     },
    //     {
    //       "no" : "3",
    //       "id_voucher" : "8274ebd71",
    //       "nama_pemenang" : "Suhendra Darmawan",
    //       "no_wa" : "0854637264432",
    //       "promo_name" : "Promo maret",
    //       "id_kasir" : "1234432",
    //       "status" : 1,
    //     },
    //     {
    //       "no" : "4",
    //       "id_voucher" : "8274ebd71",
    //       "nama_pemenang" : "Suhendra Darmawan",
    //       "no_wa" : "0854637264432",
    //       "promo_name" : "Promo april",
    //       "id_kasir" : "1234432",
    //       "status" : 1,
    //     },
    //   ];

    const dispatch = useDispatch();
    useEffect(() => {

      handleRefresh();

    }, [searchText, filter]);

    const handleSearch = (value) => {
        setSearchText(value.length > 0 ? value : "")
    }

    const handleFilter = () => {
        let dataFilter = ["diklaim", "belum diklaim", ""]
        setFilter(dataFilter[countFilter])
        setCountFilter(countFilter+1)
        if (countFilter == 2) {
            setCountFilter(0)
        }
    }

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                page: page ? page : 1,
                length: 10,
                search_text: searchText,
                filter_status: filter
            }
            dispatch(Voucher.get(param, resolve));
        })
            .then(res => {
                setDataVoucher(res?.data)
                setTotalData(res?.totalCount)
                setMaxPage(Math.ceil(res?.totalCount / 10))
            })
    }

    useEffect(() => {
        handleRefresh();
    }, [page]);

    const showClaimVoucherModal = (data) => {
        setClaimVoucherModal(!claimVoucherModal)
    }

    const handleKlaim = (param) => {
        const callback = () => {
            handleRefresh()
            setClaimVoucherModal(!claimVoucherModal)
        }

        dispatch(Voucher.claimVoucher(param, callback));
        handleRefresh()
    };

    const columns = [
        {
        Header: "NO",
        accessor: "no",
        },
        {
        Header: "ID BARCODE VOUCHER",
        accessor: "id_barcode",
        },
        {
        Header: "NAMA PEMENANG",
        accessor: "nama_pemenang",
        },
        {
        Header: "NO WHATSAPP",
        accessor: "no_whatsapp",
        },
        {
        Header: "NAMA PROMO",
        accessor: "nama_promo",
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
              const { original } = row;
              const { status_klaim } = original;
      
              return (
                <TableStatus
                  status={
                    status_klaim === 0 ? 
                      "Belum Diklaim" : "Diklaim"
                  }
                  color={
                    status_klaim === 0 ? 
                      "notAvailable" : "available"
                  }
                  outline
                />
              );
            },
        },
        {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
            const { original } = row;

            return (
                <DetailButton>
                    <MenuItem onClick={() => history.push(`/voucher-detail/${original?.id}`)}>
                        <span style={{ fontSize: 14 }}>Detail</span>
                    </MenuItem>
                </DetailButton>
            );
            
        },
        },
    ];

    return (
        <div className="menu-container">
            <div>
                <HeaderMenu title="Voucher">
                    <div style={{marginRight:20}}>
                        <Row>
                            <Col md={1}>
                                <div style={{marginTop:20}}>
                                    <SearchButton
                                        placeholder="Cari voucher"
                                        toggle={(value) => handleSearch(value)}
                                    />
                                </div>
                            </Col>
                            {/* <Col md={10}>
                                <button type="button" className="button mr-2 rounded-button button-white" onClick={()=>{handleFilter()}}>
                                     Filter
                                </button>
                            </Col> */}
                            <Col md={10}>
                                <div className="dropdown rounded-button button-white">
                                    <Select
                                        value={selected}
                                        onChange={handleChange}
                                        inputProps={{
                                        name: "voucher",
                                        id: "voucher-id"
                                        }}
                                        disableUnderline
                                        style={{ width:'100%'}}
                                        // style={{ textAlign:'left' ,border:'1px solid black', padding:'10px', borderRadius:'20px', width:'150px'}}
                                    >
                                        {values.map((value, index) => {
                                        return <MenuItem value={value}>{value}</MenuItem>;
                                        })}
                                    </Select>
                                </div>
                            </Col>

                            
                            {/* <Col md={7}>
                                <button type="button" className="button mr-2 rounded-button primary button-default" onClick={()=>{showClaimVoucherModal()}}>
                                    <i class="fas fa-plus icon-button"></i>{' '} Klaim Voucher
                                </button>
                            </Col> */}
                        </Row>
                    </div>
                </HeaderMenu>
            </div>
            <div className="content-container-side">
                <div className="overflow">
                <Table
                    columns={columns}
                    data={dataVoucher}
                    pagination={{
                        page,
                        totalData,
                        maxPage,
                        toggle: setPage
                    }}
                />
                </div>
            </div>

            {/* Modal */}
            <VoucherModal
                show={claimVoucherModal}
                toggle={showClaimVoucherModal}
                action={handleKlaim}
                type="add"
            />


        </div>

    );
};

export default Index;

