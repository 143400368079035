import { Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Layout from "./components/layout";
import PrivateRoute from "./components/commons/privateRoute";

import { Role } from "./utils/Constants"

import Index from "./views/Index";
import EmptyState from "./views/emptyState";
import Login from "./views/auth/Login";
import ChangePassword from "./views/auth/ChangePassword";
import CreatePassword from "./views/auth/CreatePassword";
import Promo from "./views/promo/Index";
import DetailPromo from "./views/promo/PromoDetail";
import ParticipantList from "./views/promo/ParticipantList";
import PromoWinner from "./views/promo/PromoWinner";
import Gallery from "./views/gallery/Index";
import DetailGallery from "./views/gallery/GalleryDetail";
import VerifyPassword from "./views/auth/VerifyPassword";
import DetailParticipant from "./views/promo/ParticipantDetail";
import DetailWinner from "./views/promo/WinnerDetail";
import Voucher from "./views/voucher/Index";
import VoucherDetail from "./views/voucher/VoucherDetail";
import GenerateWinnerResult from "./views/promo/GenerateWinnerResult";
import RegenerateWinnerResult from "./views/promo/RegenerateWinnerResult";
import User from "./views/user/Index";

const admin = [Role.admin]

function App() {

  const ContentRoute = () => {
    return (
      <Layout>
        <Switch>
          <PrivateRoute 
            path="/dashboard"
            exact
            component={EmptyState}
            roles={admin}
          />
          {/* <Route path="*" component={EmptyState} /> */}
          <PrivateRoute path="/promo" component={Promo} roles={admin}/>
          <PrivateRoute  path="/promo-detail/:id" component={DetailPromo} roles={admin}/>
          <PrivateRoute  path="/promo-participant/:id" component={ParticipantList} roles={admin}/>
          <PrivateRoute  path="/promo-winner/:id" component={PromoWinner} roles={admin}/>
          <PrivateRoute  path="/participant/detail/:id_promo/:id" component={DetailParticipant} roles={admin}/>
          <PrivateRoute  path="/participant/generate-winner/:id_promo" component={GenerateWinnerResult} roles={admin}/>
          <PrivateRoute  path="/winner/detail/:id_promo/:id" component={DetailWinner} roles={admin}/>
          <PrivateRoute  path="/participant/regenerate-winner/:id_promo" component={RegenerateWinnerResult} roles={admin}/>


          <PrivateRoute  path="/gallery" component={Gallery} roles={admin}/>
          <PrivateRoute  path="/gallery-detail/:id" component={DetailGallery} roles={admin}/>
          
          <PrivateRoute  path="/voucher" component={Voucher} roles={admin}/>
          <PrivateRoute  path="/voucher-detail/:id" component={VoucherDetail} roles={admin}/>

          <PrivateRoute  path="/user" component={User} roles={admin}/>

        </Switch>
      </Layout>
    )
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login}/>
        <Route exact path="/reset-password" component={ChangePassword} />
        <Route exact path="/verify-password/:id" component={VerifyPassword}/>
        <Route exact path="/set-new-password/:id" component={CreatePassword}/>
        {/* <PrivateRoute path="*" component={EmptyState} /> */}
        <Route component={ContentRoute} />
      </Switch>
    </>
  );
}

export default App;
