import { useState, useEffect } from "react"

const Index = ({ placeholder, toggle }) => {

  const [show, setShow] = useState(false);

  function handleInputChange(event) {
    if (toggle) {
      toggle(event.target.value)
    }
  }

  return (
    <div className="search-container">
      <input className={`input-search ${show ? "show" : ""}`} placeholder={placeholder} onChange={handleInputChange} />
      <div
        className="button search-toggle mini-hide"
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          marginTop: "-20px",
        }}
        onClick={() => setShow(!show)}
      >
        <span className="material-icons-round icon-only mini-hide">search</span>
      </div>

      {/* Showing search bar */}
      <div
        className="mini-show"
        style={{ position: "relative", width: "100%" }}
      >
        <input className="input-search" placeholder={placeholder} onChange={handleInputChange} />
        <div
          className="button search-toggle"
          style={{
            cursor: "pointer",
            position: "absolute",
            marginLeft: "-50px",
          }}
        >
          <span className="material-icons-round icon-only">search</span>
        </div>
      </div>
    </div>
  );
};

export default Index;