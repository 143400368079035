import React from "react";
import { string } from "prop-types";
import "./status.scss";

const bgColor = {
  primary: "rgba(0, 171, 189, 0.1)",
  primaryLabel: "rgba(0, 171, 189, 1)",
  danger: "#FFFF",
  dangerLabel: "#E01640",
  succes: "#FFFF",
  successLabel: "#6AC12F",
  warning: "#FFFF",
  warningLabel: "#FD9517",
  expired :"#A2ACBF",
  available :"#4C82DB",
  notAvailable :"#F9C180",
  disqualification :"#FF9696",

};

function TableStatusComponent(props) {
  const { status, style, color, toggle, leftIcon, rightIcon, outline } = props;
  return (
    
    <div
      style={{
        backgroundColor: bgColor[color],
        color: "#ffffff",
        width: "fit-content",
        border: outline ? `solid 1px ${bgColor[color + "Label"]}` : "none",
        alignSelf: "center",
        ...style,
      }}
      className="status"
      onClick={toggle}
    >
      {leftIcon && (
        <span className="material-icons-round left-icon">{leftIcon}</span>
      )}

      <span>{status}</span>

      {rightIcon && (
        <span className="material-icons-round right-icon">{rightIcon}</span>
      )}
    </div>
  );
}

TableStatusComponent.propTypes = {
  name: string,
};

export default TableStatusComponent;
