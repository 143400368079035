import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Gallery from '../../../store/actions/gallery/Index'

const InputVoucherItems = ({ action, data, register, toggle }) => {
    const formikRef = useRef();
    const formData = new FormData();
    
    const fileRef = useRef();    

    const initial = data ? {
        id: data?.id,
        id_barcode: data?.id_barcode,
        nama_pemenang: data?.nama_pemenang,
        no_wa : data?.no_wa,
    } : {
        id_barcode: "",
        nama_pemenang: "",
        no_wa : "",
    }

    const validation = Yup.object().shape({
        id_barcode: Yup.string().required("ID Barcode tidak boleh kosong").nullable(),
        nama_pemenang: Yup.string().required("Nama Pemenang tidak boleh kosong").nullable(),
        no_wa : Yup.number()
            .typeError("Hanya dapat diisi angka dan lebih dari 0")
            .required("Nomor WhatsApp tidak boleh kosong")
            .nullable()
            .test('len', 'Angka harus lebih dari 10 digit', val => {
                if (val) {
                    let angka = val.toString()
                    if (angka.charAt(0) != '0') {
                        return ("0" + angka).length >= 10
                    } else {
                        return angka.length >= 10
                    }
                }
        }),
    })

    const initialTouched = {
        id_barcode: true,
        nama_pemenang: true,
        no_wa: true,
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();

                    formData.append('barcode', values.id_barcode);
                    formData.append('name', values.nama_pemenang);
                    formData.append('no_telp', values.no_wa);

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <Input
                                name="id_barcode"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("id_barcode")}
                                title="ID Barcode"
                                placeholder="Masukkan ID"
                                type="text"
                            />
                            <Input
                                name="nama_pemenang"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("nama_pemenang")}
                                title="Nama Pemenang"
                                placeholder="Masukkan Nama Pemenang"
                                type="text"
                            />
                            <Input
                                name="no_wa"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("no_wa")}
                                title="No whatsApp"
                                placeholder="Masukkan No whatsApp"
                                type="text"
                            />
                            
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={7}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Proses
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default InputVoucherItems;
