import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import Promo from '../../store/actions/promotion/Index'
import GetCity from '../../store/actions/promotion/Index'


const Index = ({ loggingIn, match }) => {

    const [dataParticipant, setDataParticipant] = useState([]);
    const dispatch = useDispatch();
    const id = match?.params?.id
    const id_promo = match?.params?.id_promo
    const [dataCity, setDataCity] = useState([]);
   
    useEffect(() => {
        handleRefresh();
        getDataCity();
    }, []);

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                id: id,
                id_promotion: id_promo
            }
            dispatch(Promo.getDetailParticipant(param, resolve));
        })
            .then(res => {
                setDataParticipant(res?.data)
            })
            console.log(dataParticipant)
    }

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
        .then(res => {
            let dataCity = []
            res?.data?.data.map((item) => {
                dataCity = [...dataCity, { value: item.id, label: item.city_name }]
            })
            setDataCity(dataCity)
        })
    }

    const getNameCity = (cityId) => {
        console.log(cityId);
        let cityName = dataCity.filter(city => city.value == cityId)
        console.log(cityName[0]?.label);
        return cityName[0]?.label
    }

    return (
        <div className="menu-container">
            <Row>
                <Col md={3} className="sidebar-gallery-col">
                <div className="sidebar-gallery">
                    <div className="header" onClick={() => history.push(`/promo-participant/${dataParticipant?.promotion_id}`)}>
                        <i className="fas fa-arrow-left" ></i> Daftar Peserta
                    </div>
            
                <div>
                    <div className="item">
                        <div>
                            <i className="material-icons-round sidebar-icon">
                                local_offer
                            </i>
                        </div>
                        <div>
                            <p>Promo yang Diikuti</p>
                            <h5>{dataParticipant.promotion_name}</h5>
                        </div>
                    </div>
                    <div className="item">
                        <i className="material-icons-round sidebar-icon">
                            location_on
                        </i>
                        <div>
                            <p>Lokasi</p>
                            <h5>{dataParticipant.location_participant}</h5>
                        </div>
                    </div>
                </div>
                
            </div>
                </Col>
                <Col md={9}>
                    <div>
                        <HeaderMenu title="Detail Peserta">
                        </HeaderMenu>
                    </div>
                    <div className="content-container-side">
                        <div className="box-rounded">
                                <table id="table-detail-gallery">
                                    <tr><th>Nama Peserta</th><td>{dataParticipant.full_name}</td></tr>
                                    <tr><th>No Whatsapp</th><td>{dataParticipant.phone_number}</td></tr>
                                    {/* <tr><th>Kota Saat Ini</th><td>{dataCity && getNameCity(dataParticipant.city_id)}</td></tr> */}
                                    <tr><th>Kota Saat Ini</th><td>{dataParticipant.city_id ? dataCity && getNameCity(dataParticipant.city_id) : "-"}</td></tr>
                                    <tr><th>Pekerjaan</th><td>{dataParticipant.job_id ? dataParticipant.job_id : "-"}</td></tr>
                                    <tr>
                                        <th>Link Foto</th>
                                        <td>
                                            <img className="img-preview-detail" src={process.env.REACT_APP_API_URL + dataParticipant.photo_url} />
                                        </td>
                                    </tr>
                                    <tr><th>Riwayat Menang</th><td>{dataParticipant.win_history ? dataParticipant.win_history : '-'}</td></tr>
                                </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Index;

