import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { Col, Row } from "reactstrap";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import SidebarVoucher from "./SidebarVoucher";
import moment from "moment";
import localization from "moment/locale/id";
import Voucher from '../../store/actions/voucher/Index'



const Index = ({ loggingIn, match }) => {
    
    const [dataVoucher, setDataVoucher] = useState([]);
    const dispatch = useDispatch();
    const id = match?.params?.id
    console.log(id);

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                id: id,
            }
            dispatch(Voucher.getDetailVoucher(param, resolve));
        })
            .then(res => {
                setDataVoucher(res?.data[0])
            })
    }

    return (
        <div className="menu-container">
        <Row>
            <Col md={3} className="sidebar-gallery-col">
              <SidebarVoucher id={id} data={dataVoucher}/>
            </Col>
            <Col md={9}>
                <div>
                    <HeaderMenu title="Detail Voucher">
                    </HeaderMenu>
                </div>
                {console.log(dataVoucher)}
                <div className="content-container-side">
                    <div className="box-rounded space-detail-voucher">
                        <table id="table-detail-voucher">
                            <tr><th>Nama Pemenang</th><td>{dataVoucher.nama_pemenang}</td></tr>
                            <tr><th>No WhatsApp</th><td>{dataVoucher.no_whatsapp}</td></tr>
                            <tr><th>Link Foto Pemenang</th><td>{process.env.REACT_APP_API_URL + dataVoucher.foto_pemenang}</td></tr>
                        </table>
                    </div>
                    <div className="box-rounded space-detail-voucher">
                        <table id="table-detail-voucher">
                            <tr><th>Tanggal Klaim Voucher</th><td>{dataVoucher.tanggal_klaim ? moment(dataVoucher.tanggal_klaim).locale("id", localization).format("L") + ', ' + moment(dataVoucher.tanggal_klaim).locale("id", localization).format("LT") : "-"}</td></tr>
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
        </div>
    );
};

export default Index;

