import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import Promo from '../../store/actions/promotion/Index'
import ConfirmationModal from "../../components/modal/ConfirmModal";
import SuccessModal from "../../components/response/Success";
import readXlsxFile from 'read-excel-file'
import GenerateModal from "./GenerateModal";
import { toastError, toastSuccess } from "../../components/commons/toast";
import Loader from "../../components/commons/Loader";
import FilterModal from "./FilterLocationModal";

const Index = ({ loggingIn, match }) => {

  const [dataParticipant, setDataParticipant] = useState([]);
  const [dataParticipantRow, setDataParticipantRow] = useState([])
  const [searchText, setSearchText] = useState("")
  const [filterCity, setFilterCity] = useState("")
  const [filterJob, setFilterJob] = useState("")
  const [paramFilterJob, setParamFilterJob] = useState("")
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalGenerate, setModalGenerate] = useState(false)
  const [enableGenerate, setEnableGenerate] = useState()
  const [id_promo, setIdPromo] = useState();
  const [dataPromo, setDataPromo] = useState();
  const [dataExcel, setDataExcel] = useState([]);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [dataWinnerPromo, setDataWinnerPromo] = useState([]);
  const formData = new FormData();

  const id = match?.params?.id
  const dispatch = useDispatch();
  const title = dataPromo? "Daftar Peserta Promo "+ dataPromo.name : "Daftar Peserta"
  useEffect(() => {

    handleRefresh();
    handleGetWinner()
  }, [searchText, page, filterCity, filterJob, paramFilterJob]);
  const handleRefresh = () => {
    new Promise((resolve) => {
        let param = {
            page: page? page: 1,
            length: 10,
            id : id,
            search_text: searchText,
            city_id: filterCity,
            job: paramFilterJob,
        }
        dispatch(Promo.getParticipant(param, resolve));
        setIdPromo(id);
    })
        .then(res => {
            setDataParticipant(res?.data)
            setTotalData(res?.totalCount)
            setMaxPage(Math.ceil(res?.totalCount / 10))
            setEnableGenerate(res?.enable_generate)
            handleGetPromo()
    })
}
const handleGetPromo = () => {
  new Promise((resolve) => {
    let param = {
        id : id,
    }
    dispatch(Promo.detail(param, resolve));
  })
    .then(res => {
       setDataPromo(res?.data)
  })
}
const handleDetailParticipant = (ids, id_promo) => {
  history.push( `/participant/detail/${id_promo}/${ids}`)
}
useEffect(() => {
  setPage(prev => prev === 1 ? null : 1)
}, [])
 
  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "")
}
  const showEditModal = (data) => {
    // setEditModal(!editModal)
    // setDataDiseaseRow(data ? data : [])
}
const showModalGenerateParticipant= (data) => {
  setModalGenerate(!showModalGenerate)
  
}
const showAddSuccessModal = (data) => {
  setAddSuccessModal(!addSuccessModal)
}

const handleGetWinner = () => {
  new Promise((resolve) => {
    let param = {
        page: 1,
        length: 10000,
        id: id,
    }
    dispatch(Promo.getWinner(param, resolve));
  })
  .then(res => {
      setDataWinnerPromo(res?.data)
  })
}

const handleGenerate = () => {
  // history.push(`/participant/generate-winner/${id_promo}`)
  
  const callback = () => {
    handleRefresh()
    setModalGenerate(!showModalGenerate)  
  }

  new Promise((resolve) => {
    let param = {
        id: id,
    }
    dispatch(Promo.generateWinner(param, resolve, callback));
  })
  .then(res => {
      // history.push({
      //   pathname: `/participant/generate-winner/${id_promo}`,
      //   state: {
      //     newData :res.data,
      //   } 
      // })

      fetch(process.env.REACT_APP_API_URL + dataPromo?.file_url)
      .then((response) => {
          response.blob().then((fileBlob) => {
              let file = new File([fileBlob], "Sample.xlsx");
              readXlsxFile(file).then((rows) => { 
                  setDataExcel(rows)
                  handleSave(res.data, rows)
              })
          })
      })
  })
}

const handleFormData = (newData) => {
  let data = newData
  data.map((item,idx) => {
    formData.append('winner_id[]',item.id)
    formData.append('voucher_id[]',item.voucher_id)
    formData.append('promotion_id[]', id)
    formData.append('outlet_type_id[]',item.outlet_type_id)
  })
}

const handleSave = (newData) => {
  showLoadingSection()
  handleFormData(newData)
  const callback = () => {
    setTimeout(function () {
      handleRefresh();
      history.push(`/promo-winner/${id}`)
    }, 3000);
  }
  dispatch(Promo.saveWinner(formData, callback(), showAddSuccessModal(), showLoadingSection()));
}

const [showLoading, setShowLoading] = useState(false);
const showLoadingSection = () => {
  setShowLoading(!showLoading)
}

const [addGenerateModal, setAddGenerateModal] = useState(false);
const showAddGenerateModal = (data) => {
  // if (dataWinnerPromo.length >= dataPromo.winner_limit) {
  //   toastError(`Pemenang ${dataPromo.name} sudah digenerate, silahkan cek daftar pemenang`);
  // } else {
    setAddGenerateModal(!addGenerateModal)
  // }
}

const handleAddGenerate = (param) => {
  const callback = () => {
      handleRefresh()
      setAddGenerateModal(!addGenerateModal)
  }

  new Promise((resolve) => {
    dispatch(Promo.generateWinner(param, resolve, callback));
  })
  .then(res => {
    handleSave(res.data)
  })
};

  const [filterModal, setFilterModal] = useState(false);
  const showFilterModal = () => {
    setFilterModal(!filterModal)
  }

  const handleFilter = (city, job) => {
    showFilterModal()
    setFilterCity(city)
    let arr_job = job.split(',').map((item)=> {return item.split("~")[1]})
    setParamFilterJob(arr_job.join(","))
    setFilterJob(job)
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "NAMA PESERTA",
      accessor: "full_name",
    },
    {
      Header: "NIK",
      accessor: "nik",
      Cell: ({ row }) => {
        const { original } = row;
        let nik = original.nik == null ? '-' : original.nik
        return nik
      }
    },
    {
      Header: "NO WHATSAPP",
      accessor: "phone_number",
    },
    {
      Header: "LOKASI",
      accessor: "location_participant",
      Cell: ({ row }) => {
        const { original } = row;
        const { id, location_participant } = original;

        return (

          <a href={original?.location_participant} target="_blank">Lihat Lokasi</a>
        );
        
      },
    },
    {
      Header: "KOTA ASAL",
      accessor: "city_name",
    },
    {
      Header: "PEKERJAAN",
      accessor: "job",
      Cell: ({ row }) => {
        const { original } = row;
        let job = original.job == null ? '-' : original.job
        return job
        
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const { original } = row;
        const { id, status } = original;

        return (

          <DetailButton>
            <MenuItem onClick={(e)=>handleDetailParticipant(original?.customer_id, id_promo)}>
                <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
          </DetailButton>
        );
        
      },
    },
  ];

  return (
    <div className="menu-container">
      {showLoading && <Loader />}
    <Row>
        <Col md={3} className="sidebar-promo-col">
          <SidebarPromo active={"promo-participant"} id={id}/>
        </Col>
        <Col md={9}>
            <div>
                <HeaderMenu title={title}>
                <Row>
                        <Col md={2}>
                            <div style={{marginTop:20, marginLeft:50}}>
                                <SearchButton
                                    placeholder="Cari Peserta"
                                    toggle={(value) => handleSearch(value)}
                                />
                            </div>
                        </Col>
                        <Col md={9}>
                            <button type="button" disabled ={enableGenerate == 0? "disabled" : ""} className= {enableGenerate == 0? "button mr-2 rounded-button disabled" : "button mr-2 rounded-button primary button-default"} style={{marginRight:3}} onClick={()=>showAddGenerateModal()}>
                              <i class="fas fa-sort-alt"></i>Generate Pemenang
                            </button>
                            <button type="button" className= "button mr-2 rounded-button primary button-default" onClick={()=>showFilterModal()}>
                              <i class="fas fa-sort-alt"></i>Filter
                            </button>
                        </Col>
                    </Row>
                </HeaderMenu>
            </div>
            <div className="content-container-side">
                <div className="overflow">
                    <Table
                        columns={columns}
                        data={dataParticipant}
                        pagination={{
                          page,
                          totalData,
                          maxPage,
                          toggle: setPage
                      }}
                    />
                    </div>
            </div>
        </Col>
    </Row>
     {/* Modal */}
     <ConfirmationModal
          show={showModalGenerate}
          toggle={showModalGenerateParticipant}
          title = {"Generate Pemenang"}
          confirm={handleGenerate}
          // data={dataPatientRow}
          isConfirm = {"Generate"}
          icon="info"
        >
          <div>
            <p>Apakah Anda yakin ingin melakukan generate pemenang promo <b>{dataPromo?.name}</b>?</p>
          </div>
        </ConfirmationModal>
      {/* <SuccessModal 
        show={showModalGenerate}
        toggle={showModalGenerateParticipant}
        title = {"Generate Pemenang Berhasil"}
        message = {"Generate Pemenang Promo Bulan Januari Berhasil!"}
      >
      </SuccessModal> */}

      <SuccessModal 
        show={addSuccessModal}
        toggle={showAddSuccessModal}
        title = {"Generate Pemenang"}
        message = {"Generate pemenang bulan ini.."}
      >
      </SuccessModal>

      <GenerateModal
        show={addGenerateModal}
        toggle={showAddGenerateModal}
        action={handleAddGenerate}
        type="add"
        id={id}
      />

      <FilterModal
        show={filterModal}
        toggle={showFilterModal}
        action={handleFilter}
        id={id}
        filterCity={filterCity}
        filterJob={filterJob}
      />
    </div>
  );
};

export default Index;

