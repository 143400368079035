import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../../utils/Constants";

let user = JSON.parse(localStorage.getItem("user_promo"));

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "token": user?.token || "" },
});

export default instance;
