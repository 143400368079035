import React, { useRef, useEffect, useState, } from 'react'
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import MultiSelect from "../../forms/Select2Multi";
import UploadIcon from "../../../assets/img/gallery/image.png"
import GetCity from '../../../store/actions/promotion/Index'
import Promo from '../../../store/actions/promotion/Index'


const GeneratePromoItems = ({ action, data, toggle, match }) => {
    const id = match
    const dispatch = useDispatch();
    const formikRef = useRef();
    const formData = new FormData();
    const fileRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""}); 
    const [dataCity, setDataCity] = useState([]);
    const [dataStore, setDataStore] = useState([]);
    const [dataJob, setDataJob] = useState([]);
    
    useEffect(() => {
        // getDetailPromo()
        getDataStore()
        getDataCity();
        getDataJob();
    }, [])

    // cr hide, gadipakek
    // const getDetailPromo = () => {
    //     new Promise((resolve) => {
    //         let param = {
    //             id: id,
    //         }
    //         dispatch(Promo.detail(param, resolve));
    //     })
    //     .then(res => {
    //         setDataPromo(res?.data)
    //     })
    // }

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
            .then(res => {
                let dataCity = []
                dataCity = [...dataCity, { value: "all", label: "PILIH SEMUA LOKASI" }]
                res?.data?.data.map((item) => {
                    dataCity = [...dataCity, { value: item.id, label: item.city_name }]
                })
                setDataCity(dataCity)
            })
    }

    const getDataStore = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(Promo.getTypeStore(param, resolve));
        })
            .then(res => {
                console.log('res == ', res)
                let listStore = []
                res?.data?.map((item) => {
                    listStore = [...listStore, { value: item.id, label: item.name }]
                })
                setDataStore(listStore)
            })
    }

    const getDataJob = () => {
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_job(resolve, param));
        })
        .then(res => {
            let dataJob = []
            dataJob = [...dataJob, { value: "all", label: "Pilih Semua Pekerjaan"}]
            res?.data?.data.map((item)=>{
                dataJob = [...dataJob, { value: item.id+"~"+item.name, label: item.name }]
            })
            dataJob.push({value: "other~other", label: "Lainnya"})
            setDataJob(dataJob)
        })
    }

    const initial = data ? {
        // id: data?.id,
        city: "",
        toko: "",
        job: ""
    } : {
        city: "",
        toko: "",
        job:""
    }

    const validation = Yup.object().shape({
        city: Yup.string().required("Pilih kota terlebih dahulu"),
        toko: Yup.string().required("Pilih toko terlebih dahulu"),
        job: Yup.string().required("Pilih pekerjaan terlebih dahulu")
    })

    const initialTouched = {
        city: true,
        toko: true,
        job: true
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    if(values.job !== "all"){
                        let arr_job = values.job.split(',').map((item)=> {return item.split("~")[1]})
                        formData.append('job',arr_job.join(","))
                    }else{
                        formData.append('job',values.job)
                    }
                    formData.append('id',id)
                    formData.append('city_id', values.city)
                    formData.append('outlet_type_id', values.toko)

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            
                            <Select2
                                title="TOKO"
                                name="toko"
                                options={dataStore}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.toko}
                                onChange={(name, value) => {
                                    props?.setFieldValue("toko", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                            />
                            <MultiSelect
                                title="Pilih Kota/Kabupaten"
                                name="city"
                                options={dataCity}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.city}
                                onChange={(name, value) => {
                                    props?.setFieldValue("city", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                            />

                             <MultiSelect
                                title="Pilih Pekerjaan"
                                name="job"
                                options={dataJob}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.job}
                                onChange={(name, value) => {
                                    props?.setFieldValue("job", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                            />
                            
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={7}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        generate
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default GeneratePromoItems;
