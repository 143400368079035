import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Gallery from '../../../store/actions/gallery/Index'

const InputUserItems = ({ action, data, register, toggle }) => {
    const formikRef = useRef();
    const formData = new FormData();
    
    const fileRef = useRef();    

    const initial = data ? {
        id: data?.id,
        nama_user: data?.nama_user,
        email_user: data?.email_user,
    } : {
        nama_user: "",
        email_user: "",
    }

    const validation = Yup.object().shape({
        nama_user: Yup.string().required("Nama tidak boleh kosong").nullable(),
        email_user: Yup.string().email("Email tidak valid").required("Email tidak boleh kosong").nullable(),
    })

    const initialTouched = {
        nama_user: true,
        email_user: true,
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();

                    formData.append('username', values.nama_user);
                    formData.append('email', values.email_user);

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <Input
                                name="nama_user"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("nama_user")}
                                title="Nama"
                                placeholder="Masukkan Nama"
                                type="text"
                            />
                            <Input
                                name="email_user"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("email_user")}
                                title="Email User & Market"
                                placeholder="Masukkan Email"
                                type="text"
                            />
                            
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={8}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Tambah User
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default InputUserItems;
