import React, { useState,useEffect,useRef } from "react";
import { Row, Col } from 'reactstrap';
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import { library } from '@fortawesome/fontawesome-svg-core';
import { history } from "../../utils/History";

const SidebarPromo = ({active, id}) => {
   const [item, setItem] = useState();
   const [id_promo, setId_promo] = useState();
   const ids = id
  
   const handleSidebar = (menu) => {
       var url = menu
       if (menu !== '/promo'){
         url = menu + '/'+ ids
       }
       history.push(url);
   }
   useEffect(() => {
       var item = active? active : null
      setItem(item)
   }, [])
    return (
        <div className="sidebar-promo">
          <div className="header" onClick={()=>handleSidebar("/promo")}>
                <i className="fas fa-arrow-left"></i> Promo
          </div>
       
          <div>
            <div className= {item == "promo-detail" ? "item active" : "item"}  onClick={(e)=>handleSidebar("/promo-detail")}>
                <div>
                    <h5>Detail Promo</h5>
                    <p>Info terkait Promo</p>
                </div>
                <div>
                    <i className="fas fa-angle-right"></i>
                </div>
            </div>
            <div className={item == "promo-participant" ? "item active" : "item"} onClick={(e)=>handleSidebar("/promo-participant")}>
                <div>
                    <h5>Daftar Peserta</h5>
                    <p>Manajemen Peserta</p>
                </div>
                <div>
                    <i className="fas fa-angle-right"></i>
                </div>
            </div>
            <div className={item == "promo-winner" ? "item active" : "item"} onClick={(e)=>handleSidebar("/promo-winner")}>
                <div >
                    <h5>Daftar Pemenang</h5>
                    <p>Manajemen Pemenang</p>
                </div>
                <div>
                    <i className="fas fa-angle-right"></i>
                </div>
            </div>
          </div>
        
      </div>
    )
}

export default SidebarPromo;