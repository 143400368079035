import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/History";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast/index";
import { getUser } from "../../../utils/User";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_SEND_EMAIL_RESET_PASSWORD_PENDING =
    "POST_SEND_EMAIL_RESET_PASSWORD_PENDING";
export const POST_SEND_EMAIL_RESET_PASSWORD_SUCCESS =
    "POST_SEND_EMAIL_RESET_PASSWORD_SUCCESS";
export const POST_SEND_EMAIL_RESET_PASSWORD_ERROR =
    "POST_SEND_EMAIL_RESET_PASSWORD_ERROR";
export const POST_VERIFY_PASSWORD_PENDING =
    "POST_VERIFY_PASSWORD_PENDING";
export const POST_VERIFY_PASSWORD_SUCCESS =
    "POST_VERIFY_PASSWORD_SUCCESS";
export const POST_VERIFY_PASSWORD_ERROR =
    "POST_VERIFY_PASSWORD_ERROR";
export const POST_CREATE_PASSWORD_PENDING =
    "POST_CREATE_PASSWORD_PENDING";
export const POST_CREATE_PASSWORD_SUCCESS =
    "POST_CREATE_PASSWORD_SUCCESS";
export const POST_CREATE_PASSWORD_ERROR =
    "POST_CREATE_PASSWORD_ERROR";


// URL: URL_{URL}
const SEND_EMAIL_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/authentications/change-password`;
const VERIFY_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/authentications/verify-password`;
const CREATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/user/newpassword`;
// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_promo')));

const send_email = (param) => (dispatch) => {
  dispatch(actionPending(POST_SEND_EMAIL_RESET_PASSWORD_PENDING));
  axios
    .post(SEND_EMAIL_RESET_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_SEND_EMAIL_RESET_PASSWORD_SUCCESS, res));
      toastSuccess("Berhasil Mengirim Email Reset Password");
      history.push('/login')
    })
    .catch((err) => {
      dispatch(actionError(POST_SEND_EMAIL_RESET_PASSWORD_ERROR));
      toastError("Periksa kembali email Anda");
    });
};
const resetPassword = (param,callback) => (dispatch) => {
    dispatch(actionPending(POST_VERIFY_PASSWORD_PENDING));
    axios
      .post(VERIFY_PASSWORD_URL, param)
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(POST_VERIFY_PASSWORD_SUCCESS, res));
        // toastSuccess("Berhasil Mereset Password");
        // history.push('/login')
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch(actionError(POST_VERIFY_PASSWORD_ERROR));
        toastError("Periksa kembali password Anda");
      });
  };

const logout = () => {
  if (getUser() && getUser() !== null) {
    localStorage.removeItem("user_clinic");
    localStorage.removeItem("persist:root");
    history.push("/login");
  }
};

const setPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_CREATE_PASSWORD_PENDING));
    axios
      .post(CREATE_PASSWORD_URL, param)
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(POST_CREATE_PASSWORD_SUCCESS, res));
        toastSuccess("Berhasil Membuat Password");
        history.push('/login')
      })
      .catch((err) => {
        dispatch(actionError(POST_CREATE_PASSWORD_ERROR));
        toastError(err?.response?.data?.message);
      });
}

const Auth = {
  send_email,
  logout,
  resetPassword,
  setPassword
};
export default Auth;
