import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from "../../utils/History";
import Input from "../../components/forms/Input/index";

import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import { Col, Row } from "reactstrap";
import SearchButton from "../../components/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Sidebar from "../../components/layout/index";
import SidebarPromo from "./SidebarPromo";
import Promo from '../../store/actions/promotion/Index'
import moment from "moment";
import localization from "moment/locale/id";
import GetCity from '../../store/actions/promotion/Index'


const Index = ({ loggingIn, match }) => {

    const [dataWinner, setDataWinner] = useState([]);
    const dispatch = useDispatch();
    const id = match?.params?.id
    const id_promo = match?.params?.id_promo
    const [dataCity, setDataCity] = useState([]);
   
    useEffect(() => {
        handleRefresh();
        getDataCity();
    }, []);

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                id: id,
                id_promotion: id_promo
            }
            dispatch(Promo.getDetailWinner(param, resolve));
        })
            .then(res => {
                setDataWinner(res?.data)
            })
            console.log(dataWinner)
    }

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
        .then(res => {
            let dataCity = []
            res?.data?.data.map((item) => {
                dataCity = [...dataCity, { value: item.id, label: item.city_name }]
            })
            setDataCity(dataCity)
        })
    }

    const getNameCity = (cityId) => {
        console.log(cityId);
        let cityName = dataCity.filter(city => city.value == cityId)
        console.log(cityName[0]?.label);
        return cityName[0]?.label
    }


  return (
    <div className="menu-container">
    <Row>
        <Col md={3} className="sidebar-gallery-col">
        <div className="sidebar-gallery">
          <div className="header" style={{fontSize:23}} onClick={() => history.push(`/promo-winner/${id_promo}`)}>
                <i className="fas fa-arrow-left" ></i> Daftar Pemenang
          </div>
       
          <div>
            <div className="item">
                <div>
                    <i className="material-icons-round sidebar-icon">
                        date_range
                    </i>
                </div>
                <div>
                    <p>Tanggal Broadcast</p>
                    <h5>{dataWinner.broadcasted_at? moment(dataWinner.broadcasted_at).locale("id",localization).format('L') + ', ' + moment(dataWinner.broadcasted_at).locale("id", localization).format("LT") : "-"}</h5>
                </div>
            </div>
            <div className="item">
                <i className="material-icons-round sidebar-icon">
                    confirmation_number
                </i>
                <div>
                    <p>ID Voucher</p>
                    <h5>{dataWinner.voucher_id}</h5>
                </div>
            </div>
            <div className="item">
                <i className="material-icons-round sidebar-icon">
                    info
                </i>
                <div>
                    <p>Status</p>
                    <h5>{dataWinner.status == 0 ? "Belum Dikonfirmasi" : dataWinner.status == 1 ? "Dikonfirmasi" : "Diskualifikasi"}</h5>
                </div>
            </div>
          </div>
        
      </div>
        </Col>
        <Col md={9}>
            <div>
                <HeaderMenu title="Detail Pemenang">
                </HeaderMenu>
            </div>
            <div className="content-container-side">
                <div className="box-rounded">
                        <table id="table-detail-gallery">
                            <tr><th>Nama Peserta</th><td>{dataWinner.full_name}</td></tr>
                            <tr><th>No Whatsapp</th><td>{dataWinner.phone_number}</td></tr>
                            <tr><th>Link Foto Pemenang</th><td><a href={process.env.REACT_APP_API_URL + dataWinner.photo_url} target="_blank">{process.env.REACT_APP_API_URL + dataWinner.photo_url}</a></td></tr>
                            <tr><th>Lokasi Peserta</th><td><a href={dataWinner.location_participant} target="_blank">Lokasi peserta</a></td></tr>
                            {/* <tr><th>Link foto voucher</th><td><a href={process.env.REACT_APP_API_URL + dataWinner.image_url} target="_blank">{process.env.REACT_APP_API_URL + dataWinner.image_url}</a></td></tr> */}
                            <tr><th>Kota Saat Ini</th><td>{dataWinner.city_id ? dataCity && getNameCity(dataWinner.city_id) : "-"}</td></tr>
                            <tr><th>Tanggal Konfirmasi</th><td>{dataWinner.confirmation_at? moment(dataWinner.confirmation_at).locale("id",localization).format('L') + ', ' + moment(dataWinner.confirmation_at).locale("id", localization).format("LT") : "-"}</td></tr>
                        </table>
                </div>
            </div>
        </Col>
    </Row>
    </div>
);
};

export default Index;

