import React, { useRef, useEffect, useState, } from 'react'
import { connect, useDispatch } from "react-redux";
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import MultiSelect from "../../forms/Select2Multi";
import UploadIcon from "../../../assets/img/gallery/image.png"
import GetCity from '../../../store/actions/promotion/Index'


const FilterLocationItems = ({ action, data, toggle, match }) => {
    const id = match
    const dispatch = useDispatch();
    const formikRef = useRef();
    const formData = new FormData();
    const fileRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""}); 
    const [dataCity, setDataCity] = useState([]);
    const [dataToko, setDataToko] = useState([]);
    
    useEffect(() => {
        getDataCity();
        getDataToko();
    }, [])

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
            .then(res => {
                let dataCity = []
                dataCity = [...dataCity, { value: "all", label: "PILIH SEMUA LOKASI" }]
                res?.data?.data.map((item) => {
                    dataCity = [...dataCity, { value: item.id, label: item.city_name }]
                })
                setDataCity(dataCity)
            })
    }

    const getDataToko = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.getTypeStore(param, resolve));
        }).then(res => {
            let listStore = []
            res?.data?.map((item) => {
                listStore = [...listStore, { value: item.id, label: item.name }]
            })
            setDataToko(listStore)
        })

    }

    const initial = data ? {
        // id: data?.id,
        city: "",
        toko: ""
    } : {
        city: "",
        toko: ""
    }

    const validation = Yup.object().shape({
        city: Yup.string().required("Pilih kota terlebih dahulu"),
        toko: Yup.string().required("Pilih toko terlebih dahulu"),
    })

    const initialTouched = {
        city: true,
        toko: true
    }

    

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    let sendData = {
                        city : '',
                        store : ''
                    }
                    if(values.city == 'all'){
                        sendData.city = ''
                    } else {
                        sendData.city = values.city
                    }
                    sendData.store = values.toko
                    action(sendData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <div style={{marginBottom:20}}>
                                <Select2
                                    title="Toko"
                                    name="toko"
                                    options={dataToko}
                                    errors={props?.errors}
                                    touched={props?.touched}
                                    value={props?.values?.toko}
                                    onChange={(name, value) => {
                                        props?.setFieldValue("toko", String(value));
                                    }}
                                    onBlur={props?.setFieldTouched}
                                />
                            </div>

                            <MultiSelect
                                title="Kota Asal"
                                name="city"
                                options={dataCity}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.city}
                                onChange={(name, value) => {
                                    props?.setFieldValue("city", String(value));
                                }}
                                onBlur={props?.setFieldTouched}
                                
                            />
                            {/* <Select2
                                name="city"
                                options={dataCity}
                                errors={props?.errors}
                                touched={props?.touched}
                                value={props?.values?.city}
                                onChange={(name, value) => {
                                    props?.setFieldValue("city", value);
                                }}
                                onBlur={props?.setFieldTouched}
                                
                            /> */}
                            
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={7}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Terapkan
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default FilterLocationItems;
