import React, { useRef, useState, } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import Button from '../../buttons/Button'
import Input from "../../forms/Input";
import Textarea from "../../forms/Teaxtarea";
import Select2 from "../../forms/Select2";
import UploadIcon from "../../../assets/img/gallery/image.png"
import image_excel from "../../../assets/img/gallery/image_excel.png"


const InputPromoItems = ({ action, data, register, toggle }) => {

    const formikRef = useRef();
    const formData = new FormData();
    const fileRef = useRef();
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""});    

    const initial = data ? {
        id: data?.id,
        name : data.name,
        voucher_digital : `${data?.voucher_image ? process.env.REACT_APP_API_URL + data?.voucher_image : ""}`
    } : {
        voucher_digital: ""
    }

    const validationFile = (file) => {
        if (file.includes('.jpg') || file.includes('.jpeg') || file.includes('.png')) {
            return false
        }
        return true
    };

    const validationFormik = (file) => {
        if (typeof file !== 'object') {
            if (file.includes('cpi-promo')) {
                return true
            }
        }
        return false
    };

    const validation = Yup.object().shape({
        voucher_digital : Yup.mixed()
        .test('fileFormat', 'Empty File', (value) => {
            if (!value) {
                setFile({error:"File tidak boleh kosong"})
                return false
            } else if (validationFormik(value)){
                setFile({error:"Silahkan unggah file terlebih dahulu"})
                return false
            } else if(validationFile(value.name)){
                setFile({error:"File harus berformat .jpg, .jpeg atau .png"})
                return false
            }else {
                setFile({error:""})
                return true
            }

        }),
    })

    const handleChangeImage = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('voucher_digital',e.target.files[0])
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
                filename: e.target.files[0].name
            });
        }
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initial}
                // initialTouched={initialTouched}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    
                    formData.append('id',values.id)
                    formData.append('voucher_image',values.voucher_digital)

                    action(formData);
                }}
            >
                {(props) => (
                    <Form>
                        <Container>
                            <div className="form-group">
                                <div className="box-upload" >
                                    <label htmlFor="upload-button">
                                        {image.preview ? (
                                            <img className="img-preview" src={image.preview} alt="dummy"  />
                                        ) : (
                                        <>
                                            {/* <img className="img-preview" src={UploadIcon} /> */}
                                            <img className="img-preview" src={initial.voucher_digital ? initial.voucher_digital : UploadIcon} />
                                        </>
                                        )}
                                        <div className="filename">
                                            <p><b>Unggah Foto</b></p>
                                            <p>{ image.filename ? image.filename : initial.voucher_digital ? initial.name+'.jpg' :"Format jpg, jpeg, png"}</p>
                                        </div>
                                    </label>
                                </div>
                                {fileImage.error ? (
                                    <p class="warn-text error-message">{fileImage.error}</p>
                                ) : (
                                    <p></p>
                                )}
                                <input
                                    type="file"
                                    ref={fileRef}
                                    id="upload-button"
                                    style={{ display: "none" }}
                                    onChange={(e)=> handleChangeImage(e, props)}
                                    accept=".jpg,.jpeg,.png"
                                    errors={props?.errors}
                                />
                            </div>
                            <Row style={{marginTop: 20, justifyContent:"end"}}>
                                <Col sm={8}>
                                    <button type="button" className="button-batal" onClick={toggle}>
                                        Batal
                                    </button>
                                    <button type="submit" className="button-simpan">
                                        Upload File
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default InputPromoItems;
