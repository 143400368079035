import API from "../API";
import { actionPending, actionSuccess, actionError } from "../action_types/index";
import { toastError, toastSuccess } from "../../../components/commons/toast";
import axios from "axios";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const POST_USER_PENDING = "POST_USER_PENDING";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_ERROR = "POST_USER_ERROR";
export const DELETE_USER_PENDING = "DELETE_USER_PENDING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const USER_DATA = 'USER_DATA';

const USER_LIST_URL = "/user"
const USER_CREATE_URL = "/user/create"
const USER_DELETE_URL = "/user/delete"

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_USER_PENDING));
    API.post(USER_LIST_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_USER_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_USER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const addUser = (param, callback, showAddSuccessModal) => (dispatch) => {
    dispatch(actionPending(POST_USER_PENDING));
    API.post(USER_CREATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_USER_SUCCESS, res));
            // toastSuccess("Berhasil menambahkan user");
            if(showAddSuccessModal){
                showAddSuccessModal()
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_USER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const deletedUser = (param, callback) => (dispatch) => {
    dispatch(actionPending(DELETE_USER_PENDING));
    API.post(USER_DELETE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_USER_SUCCESS, res));
            if (callback) {
                callback();
            }
            toastSuccess("Berhasil menghapus user");
        })
        .catch((err) => {
            dispatch(actionError(DELETE_USER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const Management = { get, addUser, deletedUser};
export default Management;


