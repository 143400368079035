import React, { useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
import { history } from "../../utils/History";
import SuccessModal from "../../components/response/Success";

// import { withTrans } from "../../i18n/withTrans";

// ACTION REDUX
import User from "../../store/actions/user/Index";

const ChangePassword = ({ t, match }) => {

    const formikRef = useRef();
    const dispatch = useDispatch();
    const [isMatch, setIsMatch] = useState(false);
    const [matchMessage, setMatchMessage] = useState(null);
    const [checkMessage, setCheckMessage] = useState(null);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const token = match?.params?.id
    function checkPassword() {
        const values = formikRef?.current?.values;
        if (values?.password) {
            setCheckMessage(values?.password?.length > 7 ? "" : "Password minimal terdiri dari 8 karakter")
        } else {
            setCheckMessage(null)
        }
    }

    function confirmationPassword() {

        const values = formikRef?.current?.values;

        if (values?.password?.length > 7 && values?.confirmation_password) {
            if (values?.password === values?.confirmation_password) {
                setIsMatch(true)
                setMatchMessage('Password yang Anda masukkan sesuai')
            } else {
                setIsMatch(false)
                setMatchMessage('Password yang Anda masukkan tidak sesuai')
            }
        } else {
            setIsMatch(false)
            setMatchMessage(null)
        }
    }

    // const currentUser = getUser();

    // const title = currentUser ? "Reset Kata Sandi" : "Lupa Kata Sandi";
    // const subtitle = currentUser ? "Masukkan kata sandi baru untuk mereset kata sandi" : "Masukkan email terdaftar untuk  mereset kata sandi";
    const handleRedirect = () => {
        history.push("/login")
    }
    const handleShowModalSuccess = (value) => {
        setShowModalSuccess(value)
    }
    const handleRedirectLogin = () => {
        setTimeout(() => {
            history.push('/login')
          }, 5000)
    }
    return (
        <div className="main-wrapper">
            <div className="login-container">
                <div className="side-login"></div>
                <div className="content-wrapper">
                    <p className="super-title text-extra-bold">
                        {/* {title} */}
                        Ubah Kata Sandi
                    </p>
                    
                    <div className="form-wrapper">
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                               password: "",
                               confirmationPassword: "",
                               verify_token: token
                            }}
                            onSubmit={(values, { setStatus, }) => {
                                setStatus();
                                const callback = () => {
                                    handleShowModalSuccess(true)
                                    handleRedirectLogin()
                                  }
                                dispatch(User.resetPassword(values,callback));
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Input
                                        title="Password"
                                        name="password"
                                        type="password"
                                        placeholder="Masukkan password baru"
                                        errors={props.errors}
                                        touched={props.touched}
                                        onKeyUp={() => checkPassword()}
                                        {...props.getFieldProps("password")}
                                    />
                                    {checkMessage && (
                                        <div
                                            className="subtitle text-medium"
                                            style={{ fontSize: 10, marginTop: "1px", marginBottom: 10 }}
                                        >
                                            {checkMessage}
                                        </div>
                                    )}
                                    <Input
                                        title="Ulangi Password"
                                        name="confirmation_password"
                                        type="password"
                                        placeholder="Masukkan konfirmasi password"
                                        errors={props.errors}
                                        touched={props.touched}
                                        onKeyUp={() => confirmationPassword()}
                                        {...props.getFieldProps("confirmation_password")}
                                    />
                                    {matchMessage && (
                                        <div
                                            className="text-medium"
                                            style={{
                                                fontSize: 10,
                                                color: isMatch ? "#4894FE" : "#E01640",
                                                marginTop: "1px",
                                                marginBottom: 10
                                            }}
                                        >
                                            {matchMessage}
                                        </div>
                                    )}
                                    <div className="button-wrapper" style={{ marginTop: "45px" }}>
                                         <Button
                                            title="Batal"
                                            rounded
                                            type="button"
                                            variant="default"
                                            style={{ width: "45%", textAlign: "left",marginRight:15 }}
                                            onClick={handleRedirect}
                                        />
                                        <Button
                                            title="Kirim"
                                            rounded
                                            type="submit"
                                            variant="primary"
                                            style={{ width: "50%", textAlign: "Right" }}
                                            disabled={!isMatch}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div
                            style={{ marginTop: 100, position: "absolute", bottom: 10 }}
                            className="subtitle"
                        >
                            Copyright © 2021 Bigio
                        </div>
                    </div>
                </div>
                 <SuccessModal 
                    show={showModalSuccess}
                    toggle={handleShowModalSuccess}
                    title = {"Ubah Kata Sandi Berhasil"}
                    message = {"Ubah kata sandi yang Anda lakukan telah berhasil,silakan login ulang."}
                >
                </SuccessModal>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return { user };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
