export const SidebarItem = [
  {
    title: "Promo",
    slug: "promo",
    url: "/promo",
    icon: "local_offer",
  },
  {
    title: "Galeri",
    slug: "galeri",
    url: "/gallery",
    icon: "photo_library",
  },
  {
    title: "Voucher",
    slug: "voucher",
    url: "/voucher",
    icon: "confirmation_number",
  },
  {
    title: "Manajemen user",
    slug: "manajemen-user",
    url: "/user",
    icon: "settings",
  },
];
