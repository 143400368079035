import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { Col, Row } from "reactstrap";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import SidebarGallery from "./SidebarGallery";
import moment from "moment";
import localization from "moment/locale/id";
import Gallery from '../../store/actions/gallery/Index'
import UploadIcon from "../../assets/img/gallery/image.png"



const Index = ({ loggingIn, match }) => {

    const [dataGallery, setDataGallery] = useState([]);
    const [dataImage, setImage] = useState([]);
    const [dataVideo, setDataVideo] = useState([]);
    const dispatch = useDispatch();
    const id = match?.params?.id

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                id: id,
            }
            dispatch(Gallery.getDetailGallery(param, resolve));
        })
            .then(res => {
                setDataGallery(res?.data)
                stringToArray(res?.data?.image_url)
                urlToEmbed(res?.data?.video_url)
            })
    }

    const stringToArray = (dataString) => {
        dataString = dataString.replace(/\[/g, "");
        dataString = dataString.replace(/\]/g, "");
        dataString = dataString.replace(/\"/g, "");
        let array = dataString.split(",")

        setImage(array)
    }

    const urlToEmbed = (dataUrl) => {
        dataUrl = dataUrl.replace(/\[/g, "");
        dataUrl = dataUrl.replace(/\]/g, "");
        dataUrl = dataUrl.replace(/\"/g, "");
        let array = dataUrl.split(",")

        let embed = []
        array.map((item, index) => {
            item = item.replace(/\\u0026/g, "&");
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = item.match(regExp);
            
            let data = (match && match[2].length === 11)
            ? match[2]
            : null;
    
            embed.push(data)
        })
        setDataVideo(embed)
    }

    return (
        <div className="menu-container">
        <Row>
            <Col md={3} className="sidebar-gallery-col">
              <SidebarGallery id={id} data={dataGallery}/>
            </Col>
            <Col md={9}>
                <div>
                    <HeaderMenu title="Detail Galeri">
                    </HeaderMenu>
                </div>
                <div className="content-container-side">
                    <div className="box-rounded">
                            <table id="table-detail-gallery">
                                <tr><th>Isi</th><td>{dataGallery.content}</td></tr>
                                <tr>
                                    <th>Foto</th>
                                    <td>
                                        {dataImage ? (
                                            dataImage.map((item, idx) => {
                                                {console.log(process.env.REACT_APP_API_URL +  item.replace(/\"/g, ""))}
                                                return(
                                                    <img className="img-preview-detail" src={process.env.REACT_APP_API_URL + item.replace(/\"/g, "")} />
                                                )
                                            })
                                        ) : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Video</th>
                                    <td>
                                        {dataVideo ? (
                                            dataVideo.map((item, idx) => {
                                                {console.log("https://www.youtube.com/embed/"+item)}
                                                return(
                                                    <iframe width="420" height="315"
                                                        src={"https://www.youtube.com/embed/"+item}>
                                                    </iframe>
                                                )
                                            })
                                        ) : ""}
                                    </td>
                                </tr>
                            </table>
                    </div>
                </div>
            </Col>
        </Row>
        </div>
    );
};

export default Index;

