import API from "../API";
import { actionPending, actionSuccess, actionError } from "../action_types/index";
import { toastError, toastSuccess } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PROMO_PENDING = "GET_PROMO_PENDING";
export const GET_PROMO_SUCCESS = "GET_PROMO_SUCCESS";
export const GET_PROMO_ERROR   = "GET_PROMO_ERROR";
export const GET_DETAIL_PROMO_PENDING = "GET_DETAIL_PROMO_PENDING";
export const GET_DETAIL_PROMO_SUCCESS = "GET_DETAIL_PROMO_SUCCESS";
export const GET_DETAIL_PROMO_ERROR = "GET_DETAIL_PROMO_ERROR";
export const PUT_DETAIL_PROMO_PENDING = "PUT_DETAIL_PROMO_PENDING";
export const PUT_DETAIL_PROMO_SUCCESS = "PUT_DETAIL_PROMO_SUCCESS";
export const PUT_DETAIL_PROMO_ERROR = "PUT_DETAIL_PROMO_ERROR";
export const GET_PARTICIPANT_PROMO_PENDING = "GET_PARTICIPANT_PROMO_PENDING"
export const GET_PARTICIPANT_PROMO_SUCCESS = "GET_PARTICIPANT_PROMO_SUCCESS"
export const GET_PARTICIPANT_PROMO_ERROR   = "GET_PARTICIPANT_PROMO_ERROR"
export const GET_WINNER_PROMO_PENDING = "GET_WINNER_PROMO_PENDING"
export const GET_WINNER_PROMO_SUCCESS = "GET_WINNER_PROMO_SUCCESS"
export const GET_WINNER_PROMO_ERROR   = "GET_WINNER_PROMO_ERROR"
export const GET_DETAIL_PARTICIPANT_PROMO_PENDING = "GET_DETAIL_PARTICIPANT_PROMO_PENDING"
export const GET_DETAIL_PARTICIPANT_PROMO_SUCCESS = "GET_DETAIL_PARTICIPANT_PROMO_SUCCESS"
export const GET_DETAIL_PARTICIPANT_PROMO_ERROR = "GET_DETAIL_PARTICIPANT_PROMO_ERROR"
export const GET_BROADCAST_WINNER_PROMO_PENDING = "GET_BROADCAST_WINNER_PROMO_PENDING"
export const GET_BROADCAST_WINNER_PROMO_SUCCESS = "GET_BROADCAST_WINNER_PROMO_SUCCESS"
export const GET_BROADCAST_WINNER_PROMO_ERROR = "GET_BROADCAST_WINNER_PROMO_ERROR"
export const GET_GENERATE_WINNER_PENDING = "GET_GENERATE_WINNER_PENDING"
export const GET_GENERATE_WINNER_SUCCESS = "GET_GENERATE_WINNER_SUCCESS"
export const GET_GENERATE_WINNER_ERROR = "GET_GENERATE_WINNER_ERROR"
export const POST_SAVE_WINNER_PENDING = "POST_SAVE_WINNER_PENDING"
export const POST_SAVE_WINNER_SUCCESS = "POST_SAVE_WINNER_SUCCESS"
export const POST_SAVE_WINNER_ERROR = "POST_SAVE_WINNER_ERROR"
export const POST_SAVE_BROADCAST_PENDING = "POST_SAVE_BROADCAST_PENDING"
export const POST_SAVE_BROADCAST_SUCCESS = "POST_SAVE_BROADCAST_SUCCESS"
export const POST_SAVE_BROADCAST_ERROR = "POST_SAVE_BROADCAST_ERROR"
export const POST_DISQ_WINNER_PENDING = "POST_DISQ_WINNER_PENDING"
export const POST_DISQ_WINNER_SUCCESS = "POST_DISQ_WINNER_SUCCESS"
export const POST_DISQ_WINNER_ERROR = "POST_DISQ_WINNER_ERROR"
export const GET_REGENERATE_WINNER_PENDING = "GET_REGENERATE_WINNER_PENDING"
export const GET_REGENERATE_WINNER_SUCCESS = "GET_REGENERATE_WINNER_SUCCESS"
export const GET_REGENERATE_WINNER_ERROR = "GET_REGENERATE_WINNER_ERROR"
export const GET_DETAIL_WINNER_PENDING = "GET_DETAIL_WINNER_PENDING"
export const GET_DETAIL_WINNER_SUCCESS = "GET_DETAIL_WINNER_SUCCESS"
export const GET_DETAIL_WINNER_ERROR = "GET_DETAIL_WINNER_ERROR"
export const PUT_ID_VOUCHER_PROMO_PENDING = "PUT_ID_VOUCHER_PROMO_PENDING";
export const PUT_ID_VOUCHER_PROMO_SUCCESS = "PUT_ID_VOUCHER_PROMO_SUCCESS";
export const PUT_ID_VOUCHER_PROMO_ERROR = "PUT_ID_VOUCHER_PROMO_ERROR";
export const GET_CITY_PENDING = "GET_CITY_PENDING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";
export const PUT_VOUCHER_DIGITAL_PENDING = "PUT_VOUCHER_DIGITAL_PENDING";
export const PUT_VOUCHER_DIGITAL_SUCCESS = "PUT_VOUCHER_DIGITAL_SUCCESS";
export const PUT_VOUCHER_DIGITAL_ERROR = "PUT_VOUCHER_DIGITAL_ERROR";
export const GET_TYPE_STORE_PENDING = "GET_TYPE_STORE_PENDING";
export const GET_TYPE_STORE_SUCCESS = "GET_TYPE_STORE_SUCCESS";
export const GET_TYPE_STORE_ERROR = "GET_TYPE_STORE_ERROR";
export const GET_JOB_PENDING = 'GET_JOB_PENDING';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_ERROR = 'GET_JOB_ERROR';

export const PROMO_DATA = 'PROMO_DATA';

const PROMO_LIST_URL = "/promo"
const PROMO_DETAIL_URL = "/promo/detail"
const PROMO_UPDATE_URL = "/promo/update"
const PROMO_UPDATE_ID_VOUCHER_URL = "/promo/updateidvoucher"
const PARTICIPANT_LIST_URL = "/promo/participant"
const WINNER_LIST_URL = "/promo/winner"
const PARTICIPANT_DETAIL_URL = "/promo/participant/detail"
const WINNER_DETAIL_URL = "/promo/winner/detail"
const GENERATE_WINNER_URL = "promo/generate"
const REGENERATE_WINNER_URL = "promo/regenerate"
const SAVE_WINNER_URL = "promo/winner/save"
const BROADCAST_WINNER_URL = "/promo/broadcast"
const SAVE_BROADCAST_URL = "/promo/savebroadcast"
const DISQ_WINNER_URL = "/promo/winner/disqualification"
const CITY_URL = "/city";
const PROMO_UPDATE_VOUCHER_DIGITAL_URL = "/promo/upload_voucher"
const TYPE_STORE_URL = `${process.env.REACT_APP_API_URL}outletType`;
const JOB_URL = `${process.env.REACT_APP_API_URL}job`;


const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_PROMO_PENDING));
    API.post(PROMO_LIST_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const detail = (id, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_PROMO_PENDING));
    API.post(PROMO_DETAIL_URL, id)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
            if (resolve){
                let data = res.data.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const put = (param, callback) => (dispatch) => {
    dispatch(actionPending(PUT_DETAIL_PROMO_PENDING));
    API.post(PROMO_UPDATE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_DETAIL_PROMO_SUCCESS, res));
            toastSuccess("Data Promo berhasil diubah");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_DETAIL_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const putIdVoucher = (param, callback) => (dispatch) => {
    dispatch(actionPending(PUT_ID_VOUCHER_PROMO_PENDING));
    API.post(PROMO_UPDATE_ID_VOUCHER_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_ID_VOUCHER_PROMO_SUCCESS, res));
            toastSuccess("Data ID Voucher berhasil diubah");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_ID_VOUCHER_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const getParticipant = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_PROMO_PENDING));
    API.post(PARTICIPANT_LIST_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                const enable_generate = res.data.enable_generate;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                    enable_generate: enable_generate
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};
const getDetailParticipant = (param,resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_PROMO_PENDING));
    API.post(PARTICIPANT_DETAIL_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
            if (resolve) {
                let data = res.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const getWinner = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_WINNER_PROMO_PENDING));
    API.post(WINNER_LIST_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_WINNER_PROMO_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_WINNER_PROMO_ERROR));
            toastError(err?.response?.data?.message);
        });
};
const getBroadcast = (param, callback, showBcSuccessModal) => (dispatch) => {
    dispatch(actionPending(GET_BROADCAST_WINNER_PROMO_PENDING));
    API.post(BROADCAST_WINNER_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_BROADCAST_WINNER_PROMO_SUCCESS, res));
            if (callback) {
                callback();
            }
            if (showBcSuccessModal) {
                showBcSuccessModal()
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_BROADCAST_WINNER_PROMO_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};

const generateWinner = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_GENERATE_WINNER_PENDING));
    API.post(GENERATE_WINNER_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_GENERATE_WINNER_SUCCESS, res));
            if (resolve){
                let data = res.data.map((item, i) => ({
                    ...item,
                    no: i + 1 ,
                }));
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
            toastSuccess(res?.data?.message);
        })
        .catch((err) => {
            dispatch(actionError(GET_GENERATE_WINNER_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};
const saveWinner = (param,callback,showAddSuccessModal, showLoadingSection) => (dispatch) => {
    dispatch(actionPending(POST_SAVE_WINNER_PENDING));
    API.post(SAVE_WINNER_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }

            if(showLoadingSection){
                showLoadingSection()
            }
            
            dispatch(actionSuccess(POST_SAVE_WINNER_SUCCESS, res));
            
            if(showAddSuccessModal){
                showAddSuccessModal()
            }
            if (callback) {
                callback();
            }
            // toastSuccess(res?.data?.message);
        })
        .catch((err) => {
            dispatch(actionError(POST_SAVE_WINNER_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};
const saveBroadcast = (param,callback) => (dispatch) => {
    dispatch(actionPending(POST_SAVE_BROADCAST_PENDING));
    API.post(SAVE_BROADCAST_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_SAVE_BROADCAST_SUCCESS, res));
            
            if (callback) {
                callback();
            }
            // toastSuccess(res?.data?.message);
        })
        .catch((err) => {
            dispatch(actionError(POST_SAVE_BROADCAST_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};

const disqualificationWinner = (param,callback) => (dispatch) => {
    dispatch(actionPending(POST_DISQ_WINNER_PENDING));
    API.post(DISQ_WINNER_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_DISQ_WINNER_SUCCESS, res));
            
            if (callback) {
                callback();
            }
            toastSuccess("Berhasil mendiskualifikasi pemenang");
        })
        .catch((err) => {
            dispatch(actionError(POST_DISQ_WINNER_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};

const regenerateWinner = (param, resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_REGENERATE_WINNER_PENDING));
    API.post(REGENERATE_WINNER_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_REGENERATE_WINNER_SUCCESS, res));
            if (resolve){
                let data = res.data.map((item, i) => ({
                    ...item,
                    no: i + 1 ,
                    id_voucher: "",
                    upload_voucher: "",
                }));
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
            toastSuccess(res?.data?.message);
        })
        .catch((err) => {
            dispatch(actionError(GET_REGENERATE_WINNER_ERROR));
            if (callback) {
                callback();
            }
            toastError(err?.response?.data?.message);
        });
};

const getDetailWinner = (param,resolve, callback) => (dispatch) => {
    dispatch(actionPending(GET_DETAIL_WINNER_PENDING));
    API.post(WINNER_DETAIL_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_DETAIL_WINNER_SUCCESS, res));
            if (resolve) {
                let data = res.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_DETAIL_WINNER_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const get_city = (resolve, param, callback) => (dispatch) => {
    dispatch(actionPending(GET_CITY_PENDING));
    API.post(CITY_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_CITY_SUCCESS, res));
            if (resolve) {
                let data = res.data
                resolve({
                    data: data,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_CITY_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const putVoucherDigital = (param, callback) => (dispatch) => {
    dispatch(actionPending(PUT_VOUCHER_DIGITAL_PENDING));
    API.post(PROMO_UPDATE_VOUCHER_DIGITAL_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_VOUCHER_DIGITAL_SUCCESS, res));
            toastSuccess("Voucher Digital berhasil diunggah");
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_VOUCHER_DIGITAL_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const getTypeStore = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_TYPE_STORE_PENDING));
    API.post(TYPE_STORE_URL,  param )
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_TYPE_STORE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.records_total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_TYPE_STORE_ERROR));
            toastError(err?.response?.data?.message);
        });
};

const get_job = (resolve, param) => (dispatch) => {
    dispatch(actionPending(GET_JOB_PENDING));
    API.post(JOB_URL, param)
      .then((res)=>{
        if (res.error){
          throw res.error;
       } 
      dispatch(actionSuccess(GET_JOB_SUCCESS, res));
      if (resolve){
        let data = res.data
        resolve({
          data: data
        });
      }
      })
      .catch((err)=> {
        if (resolve){
          let data = err
          resolve({
            data: data.response.data
          })
        }
        dispatch(actionError(GET_JOB_ERROR));
      })
  }

const Promo = { get, detail, put, getParticipant, getDetailParticipant, getWinner, getBroadcast, generateWinner, saveWinner, saveBroadcast, disqualificationWinner, regenerateWinner, getDetailWinner, putIdVoucher, get_city, putVoucherDigital, getTypeStore, get_job};
export default Promo;