import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import ButtonModal from './ButtonModal';

function ConfirmationModal({
    show,
    toggle,
    title,
    message,
    isDelete,
    children,
    icon,
    close,
    variantIcon,
    isCancel,
    isConfirm,
    confirm
}) {

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
                toggle={toggle}>
                <ModalHeader className="text-extra-bold pt-5"> {title ?? "Konfirmasi"} <span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader>
                <ModalBody>
                    <div style={{ marginLeft: 20, display: 'flex', lineHeight: '2rem' }}>
                        {icon && <i className={`material-icons-outlined icon-modal ${variantIcon}`} style={{}}>{icon}</i>}
                        {message && <p style={{ fontSize: '16px' }}>{message}</p>}
                        {children}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonModal
                        toggle={toggle}
                        deleted={isDelete}
                        confirm={confirm}
                        cancelTitle={isCancel ?? 'Batal'}
                        confirmTitle={isConfirm ?? 'Simpan'}
                    />
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfirmationModal
