import { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { SidebarItem } from "./sidebarItem";
import Profile from "./profile";
import Notification from "./notification";

const Index = ({}) => {
  return (
    <nav className="nav-container">
      <Profile />
      {/* <Notification /> */}
      <ul className="nav-ul">
        {SidebarItem.map((item, key) => {
          return (
            <li className="nav-items" key={key}>
              <NavLink to={item.url}>
                <span className="material-icons-round sidebar-icon">
                  {item.icon}
                </span>
                <span className="sidebar-title">{item.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default withRouter(Index);
