
import { Button } from "reactstrap";

const Index = ({
  onClick,
  leftIcon,
  rightIcon,
  iconOnly,
  title,
  variant,
  rounded,
  style,
  type,
  disabled
}) => {

  return (
    <Button
      className={`button mr-2 ${variant} ${rounded ? "rounded-button" : ""}`}
      style={style}
      type={type ?? 'button'}
      onClick={onClick}
      disabled={disabled ? disabled : false}
    >
      {leftIcon && (
        <span className="material-icons-round left-icon">{leftIcon}</span>
      )}

      {iconOnly && (
        <span className="material-icons-round icon-only">{iconOnly}</span>
      )}

      {title ?? ""}

      {rightIcon && (
        <span className="material-icons-round right-icon">{rightIcon}</span>
      )}

    </Button>
  )

}

export default Index